.placeOrderPage label {
  display: block;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
.placeOrderPage select option {
  /* background: rgb(27 22 75); */
  background: #201f21;
}
.placeOrderPage select {
  /* max-width: 150px; */
  width: 100%;
  /* border: 1px solid #E6E6E6; */
  background: rgba(247, 248, 252, 0.2);
  border-radius: 5px;
  border: none;
  font-size: 10px;
  padding: 10px;
  color: #fff;
}
.placeOrderPage .rate {
  color: #bebebe;
  font-size: 14px;
  display: inline-block;
  margin-left: 10px;
}
.placeOrderPage input::placeholder {
  color: #56687f;
}
.placeOrderPage input {
  border: none;
  /* background: #F7F8FC; */
  background: var(--themeBg);
  color: #bebebe;
  font-size: 14px;
  /* margin: 12px 0; */
  padding: 10px 15px;
  width: 100%;
}
.placeOrderPage .inputDiv {
  position: relative;
  max-width: 340px;
  width: 100%;
}
.placeOrderPage .availbleUsd {
  font-size: 14px;
  color: #bebebe;
  margin-bottom: 16px;
  font-weight: 500;
}
.placeOrderPage .buyMain {
  display: flex;
  align-items: center;
  background: rgba(247, 248, 252, 0.2);
  max-width: 240px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.placeOrderPage .buyMain button.active {
  background: var(--themeGreen);
}
.placeOrderPage .buyMain button {
  background: transparent;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 6px;
}
.placeOrderPage .orderTableMain {
  background: var(--themeBg);
  padding: 16px;
  border-radius: 8px;
  font-size: 12px;
}
.placeOrderPage .orderTableMain .table th {
  background: transparent;
  color: white;
  font-weight: 500;
}

.placeOrderPage .orderTableMain .table tr td:last-child {
  text-align: right;
}
.placeOrderPage .orderTableMain .sellTable tr td:first-child {
  color: #e6496a;
  text-align: left;
}
.placeOrderPage .orderTableMain .buyTable tr td:first-child {
  color: #03c074;
  text-align: left;
}
.placeOrderPage .orderTableMain .table tr th:last-child {
  text-align: right;
}
.placeOrderPage .orderTableMain .table tr th:first-child {
  text-align: left;
}
.placeOrderPage .orderTableMain .table td,
.placeOrderPage .orderTableMain .table th {
  padding: 5px;
}
.placeOrderPage .orderTableMain .table td {
  color: white;
}
.placeOrderPage .orderTableMain .balanceMin {
  display: flex;
  align-items: center;
}
.placeOrderPage .orderTableMain .balanceMin img {
  width: 25px;
}
.placeOrderPage .orderTableMain .balanceMin span {
  color: #03c074;
  display: inline-block;
  margin-right: 10px;
  font-size: 26px;
  font-weight: bold;
}
.placeOrderPage .currentPrice .btnRightCut {
  max-width: 230px;
  display: flex;
  height: 62px;
  color: #03c074;
}
.placeOrderPage .balanceBtn .innerBtn {
  font-size: 18px;
}
.placeOrderPage .profileBannerMain {
  margin-top: 30px;
}
.placeOrderPage .profileBInnerM {
  padding: 20px;
  background: radial-gradient(
    59.4% 49.45% at 63.52% 54.79%,
    #45329c 0%,
    #090040 92%
  );
}
.placeOrderPage .placeOrderForm {
  position: relative;
  padding: 20px 30px;
  /* max-width: 620px; */
  margin: 0 auto;
  /* min-height: 550px; */
  display: flex;
  align-items: center;
}
.placeOrderPage .placeOrderForm .innerForm {
  margin: auto;
  width: 100%;
}
.placeOrderPage .placeOrderForm .formBorder {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.placeOrderPage .placeOrderForm .formBorder img {
  width: 100%;
  height: 100%;
}
.placeOrderPage .placeOrderForm .inputBorder {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 55px;
}
.placeOrderPage .placeOrderForm select option {
  background: #201f21;
}
.placeOrderPage
  .placeOrderForm
  .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator
  svg
  path {
  fill: #fff !important;
}
.placeOrderPage .placeOrderForm input,
.placeOrderPage .placeOrderForm .input {
  background: transparent;
  padding: 6px 10px !important;
  font-size: 14px;
  outline: none;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #3c3c3c;
  background: var(--themeBg);
  /* background: #201F21; */
}
.placeOrderPage .placeOrderForm .inpuRow {
  display: flex;
}
.placeOrderPage .placeOrderForm .inpuRow .inputMain:first-child {
  margin-right: 7px;
}
.placeOrderPage .placeOrderForm .inpuRow .inputMain:last-child {
  margin-left: 7px;
}
.placeOrderPage .placeOrderForm .inpuRow .inputMain {
  width: 50%;
}
.placeOrderPage .placeOrderForm .form-group input:focus,
.placeOrderPage .placeOrderForm .form-group select:focus {
  box-shadow: none !important;
  outline: none;
}
.placeOrderPage .placeOrderForm .form-group {
  position: relative;
  margin-bottom: 0;
}
.placeOrderPage .placeOrderForm .form-group .css-1oz3td-MuiAutocomplete-root {
  width: 100%;
}
.placeOrderPage
  .placeOrderForm
  .form-group
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0 !important;
}
.placeOrderPage .placeOrderForm .rate {
  font-size: 12.5px;
  margin-bottom: 20px;
}
.placeOrderPage .placeOrderForm .available {
  color: var(--themeGreen);
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.placeOrderPage .placeOrderForm .maxText {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 14px;
}
.placeOrderPage .placeOrderForm label {
  color: var(--White, #fff);
  /* font-family: Space Grotesk; */
  font-size: 14.52px;
  margin-bottom: 5px;
  font-weight: 400;
}
.placeOrderPage .placeOrderForm .css-gcwvw8-MuiAutocomplete-root {
  width: 100% !important;
}

.placeOrderPage
  .placeOrderForm
  .MuiAutocomplete-hasPopupIcon.css-gcwvw8-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.placeOrderPage
  .placeOrderForm
  .MuiAutocomplete-hasClearIcon.css-gcwvw8-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 0 !important;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
}
.pagination > li {
  margin: 0px 10px;
}
.pagination > .selected {
  color: #5151ff;
}

.ongoing-order-div {
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: rgb(5, 180, 239, 0.051);
  padding: 25px 19px;
}
.ongoing-order-div .order-type {
  background-color: rgb(5, 180, 239);
  display: inline-block;
  border-radius: 8px;
  padding: 3px 15px;
  font-size: 14px;
}

.ongoing-order-div .order-details {
  color: #bebebe;
  font-weight: 400;
}

.ongoing-order-div .order-details > div > div {
  margin-top: 5px;
}
.placeOrderPage .orHistoryMain {
  display: flex;
  height: 380px;
  overflow-x: auto;
}
.placeOrderPage .orHistoryMain .sell {
  position: relative;
  width: 100%;
}
.placeOrderPage .orHistoryMain .buy {
  position: relative;
  width: 100%;
}
.placeOrderPage .orHistoryMain .large {
  width: 165px;
  height: 165px;
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  animation: beat 0.5s infinite alternate;
}

.placeOrderPage .orHistoryMain .medium {
  width: 145px;
  height: 145px;
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  animation: beat 0.4s infinite alternate;
}

.placeOrderPage .orHistoryMain .large,
.placeOrderPage .orHistoryMain .medium,
.placeOrderPage .orHistoryMain .small {
  margin: 5px;
}
.placeOrderPage .orHistoryMain .large img,
.placeOrderPage .orHistoryMain .medium img,
.placeOrderPage .orHistoryMain .small img {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
  left: 0;
}
.placeOrderPage .orHistoryMain .small {
  width: 84px;
  height: 84px;
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  animation: beat 0.4s infinite alternate;
}
/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.04);
  }
}
.placeOrderPage .orHistoryMain p {
  margin-bottom: 0;
}
.placeOrderPage .orHistoryMain .large p {
  font-size: 14px;
}
.placeOrderPage .orHistoryMain .medium p {
  font-size: 12px;
}
.placeOrderPage .orHistoryMain .small p {
  font-size: 8.5px;
}
.placeOrderPage .orHistoryMain .row1,
.placeOrderPage .orHistoryMain .row2 {
  display: flex;
  justify-content: space-around;
}
.placeOrderPage .down100px {
  position: relative;
  top: 100px;
}
.placeOrderPage .down80px {
  position: relative;
  top: 80px;
}
.placeOrderPage .down30px {
  position: relative;
  top: 30px;
}
.placeOrderPage .down40px {
  position: relative;
  top: 40px;
}
.placeOrderPage .down50px {
  position: relative;
  top: 50px;
}
.placeOrderPage .placeOrderBtnM .innerBtn.active {
  background: var(--themeGreen);
  color: var(--themeBg);
}
.placeOrderPage .placeOrderBtnM .innerBtn {
  position: relative;
  padding: 4px 10px;
  background: transparent;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 50%;
}
.placeOrderPage .placeOrderBtnM {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  border-radius: 25px;
  background: var(--themeBg);
  padding: 8px;
}
.tradingview-widget-container {
  height: 460px;
}
.tradingview-widget-copyright {
  display: none !important;
}
#tradingview_dbed6 {
  height: 100%;
}

.placeOrderPage .themeCardGlobal.orderList {
  background: #1e1d1f;
}
.placeOrderPage .currentPNewM {
  padding: 20px 10px;
  background: var(--cardBg);
  /* background: #1E1D1F; */
  border-top: 1px solid #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
  /* transform: scale(1.4); */
  margin-left: -32px;
  margin-right: -32px;
  position: relative;
  z-index: 7;
}
.placeOrderPage .currentPNewM .title {
  padding: 5px 12px;
  background: #3c3c3c;
  border-radius: 25px;
  font-size: 16px;
  text-align: center;
}
.placeOrderPage .currentPNewM .amount {
  font-size: 16px;
  color: #03c074;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}
.placeOrderPage .currentPNewM .amount img {
  width: 16px;
}
.exchangeSupportPage .themeCardGlobal.orderList {
  border: none;
}
.exchangeSupportPage .themeCardGlobal.orderList .table .innerDiv {
  min-height: unset;
}

.orderHisPage.orderList .table thead th > div {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 1px solid #3c3c3c !important;
  border-radius: 0 !important;
}
.orderHisPage.orderList .table thead th > div::before {
  display: none;
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .placeOrderPage .orHistoryMain .large p {
    font-size: 12px;
  }
  .placeOrderPage .orHistoryMain .large {
    width: 120px;
    height: 120px;
  }
  .placeOrderPage .orHistoryMain .medium {
    width: 105px;
    height: 105px;
  }
  .placeOrderPage .orHistoryMain .small {
    width: 80px;
    height: 80px;
  }
  .placeOrderPage .down100px {
    position: relative;
    top: 70px;
  }
  .placeOrderPage .orHistoryMain {
    height: 340px;
  }
}
@media screen and (max-width: 1200px) {
  .placeOrderPage .orHistoryMain {
    flex-direction: column;
    height: 700px;
  }
  .placeOrderPage .down100px {
    top: 50px;
  }
  .placeOrderPage .down40px {
    top: 23px;
  }
}
@media (max-width: 992px) {
  .placeOrderPage .orderTableMain {
    margin-top: 20px;
  }
  .placeOrderPage .currentPNewM {
    margin-left: 0;
    margin-right: 0;
    border: 1px solid #3c3c3c;
    border-radius: 9px;
  }
}
@media screen and (max-width: 768px) {
  .placeOrderPage .orHistoryMain .row1,
  .placeOrderPage .orHistoryMain .row2 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 500px) {
  .placeOrderPage .down100px {
    top: 0;
  }
  .placeOrderPage .down80px {
    top: 0;
  }
  .placeOrderPage .down30px {
    top: 0;
  }
  .placeOrderPage .down40px {
    top: 0;
  }
  .placeOrderPage .down50px {
    top: 0;
  }
  .placeOrderPage .orHistoryMain .large {
  }
  .placeOrderPage .placeOrderForm {
    padding: 20px 15px;
  }
}
