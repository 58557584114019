@font-face {
  font-family: BLOMBERG;
  src: url(../../assets/font/BLOMBERG.otf);
}
@font-face {
  font-family: SpaceGrotesk;
  src: url(../../assets/font/Space-Grotes/SpaceGrotesk-Medium.ttf);
}
body {
  font-family: titillium-web !important;
}
.capitalize {
  text-transform: capitalize;
}

.analytics-box-title {
  color: #8f9fb4;
  font-size: 11px;
  font-weight: 500;
}
.analytic-percentage {
  color: #28cb89;
  font-size: 12px;
}
.last-month {
  color: #abb7c7;
  font-size: 11px;
}

.border-transparent {
  border: transparent !important;
}
.br-10 {
  border-radius: 10px !important;
}
.mile {
  font-size: 13px;
}
.analytic-box-img {
  width: 35px;
}
.chart-time {
  /* font-weight: 600; */
  /* color: #8F9FB4; */
  color: #fff;
  font-size: 10px;
}
.chart-time:hover,
.chart-time:focus {
  color: var(--themeGreen);
  text-decoration: none;
}
.chart-time-active {
  color: var(--themeGreen) !important;
}
.chart-heading {
  font-weight: 600;
  /* color: #272D3B; */
  color: #fff;
  font-size: 13px;
}
.profileBannerMain .chart-heading {
  font-family: tt-firs-bold;
  color: var(--themeBlue);
}
.bg-white {
  background: #fff !important;
}
.w-4 {
  width: 4px;
}
.diver-name {
  font-family: tt-firs-bold, serif;
  font-weight: 500;
  /* color: #333333; */
  color: #fff;
  font-size: 18px;
}
.driver-status-title {
  /* font-family: jetbrain-Bold,serif; */
  font-size: 28px;
  /* font-weight: 500; */
}
.driver-img {
  width: 2rem;
  height: 2rem;
}
.driver-dash-div {
  border-top: 1px solid #eeeef0;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.dark-font {
  color: #333333;
}
.status-loaded {
  color: #17c903 !important;
}
.status-waiting {
  color: #f51f3c !important;
}
.status-offline {
  color: #ffa500 !important;
}
.overflow-hidden {
  overflow: hidden;
}
/* chart style */
.chart-area svg {
  width: 100%;
  height: 100%;
}
.chart-area path {
  stroke-width: 2;
}
.chart-area circle {
  width: 0;
}
.chart-area text {
  color: #fff;
  fill: #fff;
}
.chart-area tspan {
  font-size: 10px;
  font-weight: 400;
  /* color: #272D3B; */
  color: #fff;
}
.h-100 {
  height: 100%;
}
.dashboardMain {
  /* margin-top: 50px; */
  padding-left: 30px;
  padding-right: 15px;
  color: #fff;
}
.dashboardMain .connectExMain {
  /* display: flex; */
  padding: 30px;
  /* background: #F7F8FC; */
  border: 1px solid #3c3c3c;
  background: var(--cardBg);
  border-radius: 13px;
  position: relative;
  align-items: center;
}
.borderCutLeft img {
  width: 100%;
  height: 100%;
}
.borderCutLeft {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.dashboardMain .connectExMain .connectNowBtn img {
  cursor: pointer;
}
.dashboardMain .connectExMain .connectNowBtn {
  margin-top: 30px;
}
.dashboardMain .connectExMain .col1 .btnRightCut {
  width: max-content;
  padding: 10px 20px;
  height: 50px;
}
.dashboardMain .connectExMain .col1 h1 {
  font-family: desirableSans, serif;
  /* font-weight: bold; */
  font-size: 24px;
  color: #fff;
  color: var(--themeGreen);
  /* text-shadow: 2px 2px 0px var(--Gradient); */
}
.dashboardMain .connectExMain .col1 p {
  /* font-family: SpaceGrotesk; */
  font-size: 17px;
  margin: 25px 0;
}
.dashboardMain .connectExMain .col2 .imgDiv {
  text-align: center;
}
.themeBtn:hover {
  text-decoration: none;
  box-shadow: 0 0 10px 0 var(--themeGreen) inset, 0 0 20px 2px var(--themeGreen);
  /* border: 2px solid var(--themeGreen); */
  color: var(--themeBg);
}
.themeBtn {
  font-family: titillium-web-bold;
  background: var(--themeGreen);
  border: none;
  padding: 8px 25px;
  color: var(--themeBg);
  font-weight: 500;
  border-radius: 25px;
  cursor: pointer;
}
.themeOutlineBtn {
  font-family: titillium-web-bold;
  background: transparent;
  border: 2px solid var(--themeGreen);
  padding: 5px 15px;
  color: var(--themeGreen);
  font-weight: 500;
  border-radius: 7px;
  cursor: pointer;
  font-size: 12px;
}
.themeOutlineBtn:hover {
  background: var(--themeGreen);
  text-decoration: none;
  color: #000;
}
.OrderCompelteModal {
  text-align: center;
}
.OrderCompelteModal .OrderCompelteImg {
  width: 140px;
  margin-bottom: 20px;
}
.connectModal.kycModal1 .hrLine {
  width: calc(100% + 60px);
  left: -30px;
}
.connectModal.exchModal .hrLine {
  width: calc(100% + 60px);
  left: -30px;
}
.connectModal {
  padding: 20px;
  position: relative;
}
.connectModal .closeIco {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
}
.connectModal h2 {
  text-align: center;
  color: var(--themeGreen);
  font-family: desirableSans, serif;
  text-transform: uppercase;
  font-size: 40px;
}
.connectModal .desc {
  text-align: center;
  /* font-family: Space Grotesk; */
  color: #fff;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.connectModal .optionsMain .balanceBtn .innerBtn {
  height: 31px;
}
.connectModal .optionsMain {
  display: flex;
  padding: 10px 7px;
  margin: auto;
  /* border-bottom: 2px solid #F2F2F2; */
  /* border-bottom: 2px solid rgba(255, 255, 255, 0.2); */
  border-radius: 30px;
  background: #111525;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.hr2px {
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
}
.connectModal .optionsMain .opBtn:hover,
.connectModal .optionsMain .opBtn.active {
  /* background: #e7e7e7; */
  background: var(--themeGreen);
  color: #000;
}
.connectModal .optionsMain .opBtn {
  width: 100%;
  /* background: #F2F2F2; */
  background: transparent;
  padding: 7px;
  color: #fff;
  font-size: 16px;
  border-radius: 25px;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
}
.connectModal .exchangeMain {
  display: flex;
  padding: 18px 0;
  margin-bottom: 5px;
}
.connectModal .exchangeMain .opBtn:last-child img {
  width: 79px;
}
.connectModal .exchangeMain .opBtn {
  width: 100%;
  /* background: rgba(247, 248, 252, 0.1); */
  padding: 16px;
  color: #707070;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
}

.connectModal .exchangeMain .opBtn:hover,
.connectModal .exchangeMain .opBtn.active {
  /* background: #e8e9ed; */
  background: rgba(247, 248, 252, 0.2);
}
.connectModal .connectContent {
  padding: 20px;
  background: #111525;
  border-radius: 14px;
}

.connectExPage .balanceBar .balanceBtn {
  max-width: 210px;
}
.dashboardMain .connectExPage {
  color: #fff;
}
.dashboardMain .connectExPage .head {
  display: flex;
  flex-wrap: wrap;
}
.dashboardMain .connectExPage h2 {
  margin-right: 15px;
  margin-bottom: 0;
}
.dashboardMain .connectExPage .descMain {
  display: flex;
  align-items: center;
}
.dashboardMain .connectExPage .descMain .imgDiv {
  width: 70px;
  flex-shrink: 0;
}
.dashboardMain .connectExPage .descMain p {
  font-weight: 400;
  margin-bottom: 0;
}
.dashboardMain .connectExPage .connectOpBtn {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.dashboardMain .connectExPage .connectOpBtn .cBtn.acive {
  color: #fff;
}
.dashboardMain .connectExPage .connectOpBtn .cBtn {
  padding: 4px 16px;
  /* background: #F7F8FC; */
  background: var(--themeBg1);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 15px;
  color: #56687f;
}
.dashboardMain .connectExPage .secureKeyMain {
  /* background: #F7F8FC; */
  background: var(--themeBg1);
  border-radius: 12px;
  padding: 15px 20px;
  margin: 16px 0;
}
.dashboardMain .connectExPage .secureKeyMain p {
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.5);
}
.dashboardMain .connectExPage .secureKeyMain label {
  display: block;
  font-size: 12px;
  color: #fff;
}
.dashboardMain .connectExPage .secureKeyMain input[type="radio"] {
  margin-right: 15px;
}
.dashboardMain .connectExPage .secureKeyMain .radioInput {
  display: flex;
  align-items: center;
}
.dashboardMain .connectExPage .secureKeyMain .radioInput span.active {
  color: #05b4ef;
}
.dashboardMain .connectExPage .secureKeyMain .radioInput span {
  font-size: 12px;
  color: #fff;
}
.dashboardMain .connectExPage .secureKeyMain select {
  max-width: 270px;
  width: 100%;
  /* border: 1px solid #E6E6E6; */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  font-size: 10px;
  padding: 7px;
  color: #fff;
}
.dashboardMain .connectExPage .form label {
  color: #fff;
}

.dashboardMain .connectExPage .form .borderColor {
  padding: 1px;
  background: var(--themeBlue);
  clip-path: polygon(96% 0, 100% 60%, 100% 100%, 0 99%, 0 0);
}
.borderColorInput input {
  background: linear-gradient(
    0deg,
    #45329c -40.57%,
    #090040 110.23%
  ) !important;
  border-radius: 0;
  clip-path: polygon(96% 0, 100% 60%, 100% 100%, 0 99%, 0 0);
}
.borderColorInput {
  padding: 1px;
  background: var(--themeBlue);
  clip-path: polygon(96% 0, 100% 60%, 100% 100%, 0 99%, 0 0);
}
.dashboardMain .connectExPage .form .form-control {
  position: relative;
  /* background: linear-gradient(0deg, #45329C -40.57%, #090040 110.23%); */
  background: var(--cardBg);
  border-radius: 8px;
  border: 1px solid #3c3c3c;
  font-size: 14px;
  height: 38px;
  color: #fff;
  /* clip-path: polygon(96% 0, 100% 60%, 100% 100%, 0 99%, 0 0); */
}
.dashboardMain .connectExPage .themeBtn {
  width: 160px;
}
.dashboardMain .connectExPage h2 {
  color: var(--themeGreen);
  font-family: desirableSans, serif;
}
.exchangeSupportPage h2 {
  font-family: desirableSans, serif;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: var(--themeGreen);
}
.exchangeSupportPage .connectOpBtn .balanceBtn .innerBtn {
  height: 28px;
}
.exchangeSupportPage .connectOpBtn .balanceBtn {
  max-width: unset;
  width: max-content;
  font-size: 12px;
}

.exchangeSupportPage .connectOpBtn1 .bBtn.active {
  background: var(--themeGreen);
  color: var(--themeBg);
}
.exchangeSupportPage .connectOpBtn1 .bBtn {
  position: relative;
  padding: 4px 10px;
  /* background: #F2F2F2; */
  background: transparent;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  max-width: 110px;
  width: 100%;
  text-align: center;
  /* color: #707070; */
  color: #fff;
}
.exchangeSupportPage .connectOpBtn1 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  border-radius: 8px;
  background: var(--cardBg);
  padding: 8px;
  max-width: 460px;
}
.exchangeSupportPage .connectOpBtn {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.exchangeSupportPage .connectOpBtn .cBtn.active {
  background: var(--themeGreen);
  color: var(--themeBg);
}
.exchangeSupportPage .connectOpBtn .cBtn {
  position: relative;
  padding: 4px 10px;
  /* background: #F2F2F2; */
  /* background: rgba(242, 242, 242, 0.2); */
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  /* max-width: 135px;
    width: 100%; */
  text-align: center;
  /* color: #707070; */
  color: #fff;
  margin-bottom: 10px;
}
.exchangeSupportPage .connectOpBtn .dBtn input::placeholder {
  color: #fff;
}
.exchangeSupportPage .connectOpBtn .dBtn input {
  background: transparent;
  /* border-radius: 12px; */
  /* padding: 10px 12px; */
  border: none;
  color: #fff;
  width: 100%;
}
.exchangeSupportPage .connectOpBtn .dBtn .inputBorder {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.exchangeSupportPage .connectOpBtn .dBtn {
  position: relative;
  padding: 4px 10px;
  /* background: #F2F2F2; */
  /* background: rgba(242, 242, 242, 0.2); */
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  max-width: 220px;
  width: 100%;
  text-align: center;
  /* color: #707070; */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-bottom: 10px;
}
.outerTableMain {
  padding: 1px;
  clip-path: polygon(0 0, 96% 0, 100% 12%, 100% 100%, 3% 100%, 0 88%);
  background: var(--themeBlue);
}
.outerTable {
  padding: 10px;
  background: #0e0023;
  clip-path: polygon(0 0, 96% 0, 100% 12%, 100% 100%, 3% 100%, 0 88%);
}

.exchangeSupportPage .table td .btnRightCut {
  height: auto;
}
.exchangeSupportPage .table th > div {
  padding: 10px;
}
.table td > div {
  /* background: linear-gradient(0deg, #45329C -34.28%, #090040 78.29%); */
  background: var(--themeBg);
}
.exchangeSupportPage .table td > div {
  padding: 10px 0;
  border-top: 1px solid #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
  /* background: #F7F8FC; */
  /* background: var(--themeBg1); */
  /* background: var(--Linear-grad, linear-gradient(180deg, #090040 0%, #45329C 100%)); */
}
.orderHisPage .table td > div {
  padding: 3px 0;
}
.orderHisPage .table tbody tr:first-child td {
  padding-top: 16px;
}
/* .orderHisPage .table td {
    padding: 12px 0 !important;
} */
.orderHisPage .theading {
  color: #fff;
  padding: 15px 20px;
  font-family: tt-firs-bold;
  font-size: 20px;
  border-bottom: 1px solid #3c3c3c;
}
.orderHisPage .table thead th:last-child > div::before {
  display: none;
}
.orderHisPage .table thead th > div::before {
  width: 1px;
  background: #3c3c3c;
  height: 70%;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.orderHisPage .table thead th > div {
  position: relative;
  min-height: 44px;
  border-top: 1px solid #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
  line-height: 22px;
  background-color: var(--themeBg);
}
.br-1 {
  border-right: 1px solid #3c3c3c;
}
.orderHisPage .table td > div {
  background: transparent;
}
.orderHisPage .table thead th {
  background: transparent;
  border: none !important;
}
.orderHisPage .table thead th:first-child > div {
  border-left: 1px solid #3c3c3c;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.orderHisPage .table thead th:last-child > div {
  border-right: 1px solid #3c3c3c;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.orderHisPage .table td > div {
  padding: 0 !important;
}
.orderHisPage .table .innerDiv {
  border-top: 1px solid #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
  border-right: 0px solid !important;
  background-color: var(--themeBg);
}
.orderHisPage .table td:first-child .innerDiv {
  border-left: 1px solid #3c3c3c;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.orderHisPage .table td:last-child .innerDiv {
  border-right: 1px solid #3c3c3c !important;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.orderHisPage .table td .innerDiv {
  position: relative;
}
.orderHisPage .table td:last-child .innerDiv::before {
  display: none;
}
.orderHisPage .table td .innerDiv::before {
  width: 1px;
  background: #3c3c3c;
  height: 70%;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.exchangeSupportPage .table th:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.exchangeSupportPage .table th:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.exchangeSupportPage .table tr td:first-child > div {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  margin-left: 10px;
  border-top: 1px solid #3c3c3c;
  border-left: 1px solid #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
}
.exchangeSupportPage .table tr td:last-child > div {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-right: 10px;
  border-right: 1px solid #3c3c3c;
}
.exchangeSupportPage .table .innerDiv {
  padding: 10px;
  min-height: 44px;
  /* border-right: 1px solid #e1e6f5; */
  /* border-right: 1px solid var(--themeBg1); */
  border-right: 1px solid #3c3c3c;
}
.exchangeSupportPage .table .innerDiv:last-child {
  border-right: none;
}
.table th {
  /* background: linear-gradient(205deg, #201F21 11.86%, rgba(32, 31, 33, 0.00) 376.3%); */
  /* background: linear-gradient(0deg, #45329C -34.28%, #090040 78.29%);*/
  border-bottom: 1px solid #3c3c3c !important;
}
.exchangeSupportPage .table th > div {
  font-weight: 500;
  font-size: 13px;
  /* color: #707070; */
  color: var(--themeGreen);
  font-family: tt-firs-bold;
  text-transform: uppercase;
  text-align: center;
}
.exchangeSupportPage .table th > div span {
  width: max-content;
  margin: auto;
  display: inline-block;
}
.exchangeSupportPage .table th,
.exchangeSupportPage .table td {
  border: none;
  text-align: center;
  padding: 8px 0;
}

.exchangeSupportPage .exIco {
  max-width: 59px;
  margin: auto;
}
.exchangeSupportPage .exIco img {
  max-width: 100%;
}
.exchangeSupportPage .orderTypeMain {
  display: flex;
  justify-content: center;
}
.exchangeSupportPage .orderTypeMain .orderBtn {
  padding: 4px 10px;
  /* background: rgba(5,180,239,0.1); */
  background: var(--themeGreen);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
  margin-right: 5px;
  max-width: 60px;
  width: 100%;
  text-align: center;
  color: var(--themeBg);
}
.exchangeSupportPage table .volume {
  /* color: #707070; */
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.exchangeSupportPage table .connectBtn,
.order-details > div > .connectBtn {
  font-size: 10px;
  border-radius: 4px;
  background: var(--themeGreen);
  color: var(--themeBg);
  padding: 5px 8px;
  text-align: center;
  /* max-width: 100px; */
  width: 80px;
  margin: auto;
}
.dashboardMain .apiUpdateMAin {
  padding: 20px;
  /* background: #F7F8FC; */
  border: 1px solid #3c3c3c;
  background: var(--cardBg);
  border-radius: 15px;
  /* max-width: 450px; */
  width: 100%;
  position: relative;
}
.btnRightCut span {
  z-index: 1;
}
.btnRightCut .ico {
  position: relative;
  width: 30px;
  /* margin-left: 10px; */
}
.btnRightCut img {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0;
}
.btnRightCut {
  font-family: jetbrain-Bold;
  position: relative;
  color: var(--themeBlue);
  font-size: 16px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.connectModal .btnRightCut {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.table-responsive table {
  /* background: radial-gradient(72.04% 40.67% at 50.51% 49.88%, #45329C 0%, #090040 92%);
    clip-path: polygon(0 0, 96% 0, 100% 12%, 100% 100%, 3% 100%, 0 88%); */
  margin-bottom: 0;
}
.table-responsive {
  /* padding: 1px;
    clip-path: polygon(0 0, 96% 0, 100% 12%, 100% 100%, 3% 100%, 0 88%);
    background: var(--Gradient); */
  border-radius: 15px;
  border: 1px solid #3c3c3c;
  /* background: linear-gradient(180deg, #201F21 0%, rgba(32, 31, 33, 0.00) 100%); */
  background: var(--cardBg);
}
.dashboardMain .apiUpdateMAin .row1 {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.dashboardMain .apiUpdateMAin .row1 .refresh img {
  cursor: pointer;
}
.dashboardMain .apiUpdateMAin .row1 .profileM {
  display: flex;
  align-items: center;
}
.dashboardMain .apiUpdateMAin .row1 .profileM .logoDiv {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.dashboardMain .apiUpdateMAin .row1 .profileM .logoDiv img {
  max-width: 100%;
}
.dashboardMain .apiUpdateMAin .row1 .profileM .name {
  font-family: tt-firs-bold, serif;
  font-weight: 500;
  font-size: 18px;
}
.dashboardMain .apiUpdateMAin .row2 {
  margin: 20px 0;
  text-align: center;
}
.dashboardMain .apiUpdateMAin .row2 .warning {
  width: 82px;
  margin-bottom: 15px;
}
.dashboardMain .apiUpdateMAin .row2 .text {
  color: white;
  font-weight: 500;
}
.dashboardMain .apiUpdateMAin .row2 .msg {
  font-size: 14px;
  color: #bebebe;
}
.dashboardMain .apiUpdateMAin .btnMain {
  display: flex;
  justify-content: center;
}
.dashboardMain .apiUpdateMAin .btnMain button {
  font-size: 16px;
  /* width: 100%; */
}
.connectModal .css-cgt4s4-control {
  border: 1px solid #3c3c3c00;
  padding: 9px 20px;
  background-color: var(--themeBg);
  border-radius: 25px;
}
.connectModal .css-ytvn0b-control {
  border: 1px solid #3c3c3c00;
  padding: 9px 20px;
  background-color: var(--themeBg);
  border-radius: 25px;
}
.connectModal .css-1u9des2-indicatorSeparator {
  background: #3c3c3c;
}

@media screen and (min-width: 576px) {
  .connectModal .exchangeMain .innerBtn {
    height: 43px;
  }
}
@media screen and (max-width: 576px) {
  .connectModal .balanceBtn .innerBtn {
    height: 100%;
  }
  .connectModal .balanceBtn .innerBtn small {
    display: none;
  }
  .connectModal .exchangeMain .balanceBtn {
    width: 48%;
    margin-right: 0;
  }
  .connectModal .exchangeMain {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .connectModal .exchangeMain .opBtn {
    width: 48%;
    margin-bottom: 10px;
  }
  .connectModal .exchangeMain .opBtn {
    margin-left: 0;
    margin-right: 0;
  }
  .connectModal {
    padding: 16px;
  }
  .dashboardMain {
    padding: 0;
  }
  .pl-xs-0 {
    padding-left: 0 !important;
  }
  .dashboardMain .connectExMain {
    padding: 30px 20px;
  }
  .topbar .navHead {
    margin-left: 0;
  }
}
.ql-editor > p {
  color: black;
}
.admin-tab {
  padding: 10px;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
}
.admin-tabs {
  display: flex;
  border-bottom: 1px solid white;
}
