.ongoingTransactionMain {
    margin-top: 40px;
    display: flex;
}
.ongoingTransactionMain .greenBubbleMain,
.ongoingTransactionMain .yellowBubbleMain {
    width: 340px;
    height: 340px;
    position: relative;
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.ongoingTransactionMain .col2 .greenBubbleMain,
    .ongoingTransactionMain .col2 .yellowBubbleMain {
    /* left: -60px; */
    animation: beat 1s infinite alternate;
}
.ongoingTransactionMain .redBubbleMain {
    width: 340px;
    animation: beat 1s infinite alternate;
    height: 340px;
    position: relative;
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    left: 75px;
}
.ongoingTransactionMain .greenBubbleMain img, 
.ongoingTransactionMain .yellowBubbleMain img, 
.ongoingTransactionMain .redBubbleMain img 
{
    position: absolute;
    width: 100%;
    top: 0;
    z-index: -1;
    left: 0;
}
.ongoingTransactionMain .greenBubbleMain .long, 
.ongoingTransactionMain .yellowBubbleMain .long, 
.ongoingTransactionMain .redBubbleMain .long 
{
    font-size: 17px;
    text-transform: uppercase;
    color: #F4F4F4;
    /* font-family: Inter; */
    font-weight: 700;
}
.ongoingTransactionMain .greenBubbleMain .roi,
.ongoingTransactionMain .yellowBubbleMain .roi {
    font-size: 75px;
    line-height: 0.7;
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
}
.ongoingTransactionMain .greenBubbleMain .pair,
.ongoingTransactionMain .yellowBubbleMain .pair {
    line-height: 1;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
}
.ongoingTransactionMain .redBubbleMain .roi {
    font-size: 75px;
    line-height: 0.7;
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
}
.ongoingTransactionMain .redBubbleMain .pair {
    line-height: 1;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
}
.ongoingModal .row1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
}
.ongoingModal .row1 .pair span {
    color: var(--themeGreen);
}
.ongoingModal .row1 .pair {
    color: #32E20B;
    font-family: jetbrain-Bold;
    font-size: 20px;
}
.ongoingModal .row1 .balanceBtn {
    max-width: 135px;
    background: #32E20B;
    color: #32E20B;
}
.ongoingModal .row1 .balanceBtn .innerBtn{
    height: 28px;
    color: #32E20B;
}
.ongoingModal .row2 {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    font-size: 12px;
}
.ongoingModal .row2 .col1 {
    display: flex;
}
.ongoingModal .row2 .col1 .key {
    color: #32E20B;
    margin-right: 20px;
}
@media screen and (max-width:992px) {
    .ongoingTransactionMain .col2 .greenBubbleMain,
    .ongoingTransactionMain .col2 .yellowBubbleMain {
        left: 0;
    }
    .ongoingTransactionMain {
        flex-direction: column;
    }
    .ongoingTransactionMain .col2 {
        margin-top: 20px;
    }
}
@media screen and (max-width:992px) and (min-width:576px) {
    .ongoingTransactionMain .greenBubbleMain,
    .ongoingTransactionMain .yellowBubbleMain {
        width: 290px;
        height: 290px;
    }
    .ongoingTransactionMain .greenBubbleMain .roi,
    .ongoingTransactionMain .yellowBubbleMain .roi {
        font-size: 67px;
    }
    .ongoingTransactionMain .greenBubbleMain .pair,
    .ongoingTransactionMain .yellowBubbleMain .pair {
        font-size: 26px;
    }
    .ongoingTransactionMain .redBubbleMain {
        width: 230px;
        height: 230px;
    }
    .ongoingTransactionMain .redBubbleMain .roi {
        font-size: 55px;
    }
}
@media screen and (max-width:576px) {
    .ongoingTransactionMain .greenBubbleMain,
    .ongoingTransactionMain .yellowBubbleMain {
        width: 250px;
        height: 250px;
    }
    .ongoingTransactionMain .greenBubbleMain .roi,
    .ongoingTransactionMain .yellowBubbleMain .roi {
        font-size: 50px;
    }
    .ongoingTransactionMain .greenBubbleMain .pair,
    .ongoingTransactionMain .yellowBubbleMain .pair {
        font-size: 21px;
    }
    .ongoingTransactionMain .redBubbleMain {
        width: 220px;
        height: 250px;
        left: 36px;
    }
    .ongoingModal .row1 {
        flex-direction: column;
    }
    .ongoingModal .row1 .balanceBtn {
        margin-top: 10px;
    }
    .ongoingModal .row2 {
        flex-wrap: wrap;
    }
    .ongoingTransactionMain .redBubbleMain .roi {
        font-size: 47px;
        margin-top: -40px;
    }
    .ongoingTransactionMain .redBubbleMain .pair {
        font-size: 18px;
    }
    .ongoingTransactionMain .greenBubbleMain .long, .ongoingTransactionMain .redBubbleMain .long {
        font-size: 15px;
    }
    .ongoingTransactionMain .yellowBubbleMain .long, .ongoingTransactionMain .redBubbleMain .long {
        font-size: 15px;
    }
}