/* global */
*,
::after,
::before {
  box-sizing: border-box;
}
@font-face {
  font-family: jetbrain-mono;
  src: url(../../assets/font/jetbrainMono/JetBrainsMono-Light.woff2);
}
@font-face {
  font-family: jetbrain-Bold;
  src: url(../../assets/font/jetbrainMono/JetBrainsMono-Bold.woff2);
}
@font-face {
  font-family: titillium-web;
  src: url(../../assets/font/Titillium_Web/TitilliumWeb-Regular.ttf);
}
@font-face {
  font-family: titillium-web-semibold;
  src: url(../../assets/font/Titillium_Web/TitilliumWeb-SemiBold.ttf);
}
@font-face {
  font-family: titillium-web-bold;
  src: url(../../assets/font/Titillium_Web/TitilliumWeb-Bold.ttf);
}
@font-face {
  font-family: tt-firs-bold;
  src: url(../../assets/font/tt_firs_neue/TT\ Firs\ Neue\ Trial\ DemiBold.ttf);
}
@font-face {
  font-family: desirableSans;
  src: url(../../assets/font/desireable-sans-t/DesirableSanstexture.ttf);
}
:root {
  --themeColor: #05b4ef;
  /* --themeBg:rgba( 255, 255, 255, 0.05 ); */
  /* --themeBg:#111; */
  /* --themeBg:#080808; */
  /* --themeBg:#1A1A1A; */
  --themeBg: #111525;
  --themeBg1: rgba(247, 248, 252, 0.05);
  --Pink: #f342ff;
  --Gradient: #f342ff;
  --themeBlue: #86ffff;
  --themeGreen: #67e1ae;
  /* --cardBg: linear-gradient(176deg, #201F21 -2.43%, rgba(32, 31, 33, 0.00) 129.1%); */
  /* --cardBg: #080808; */
  --cardBg: #161b2e;
}
input {
  fill: var(
    --Gradient,
    radial-gradient(72.04% 40.67% at 50.51% 49.88%, #45329c 0%, #090040 92%)
  );
  stroke-width: 0.948px;
  stroke: var(--Pink, #f342ff);
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
  transition: all 0.3s;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: desirableSans, serif;
  font-weight: 400;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
#wrapper {
  display: flex;
}
#wrapper #content-wrapper {
  /* background-color: #fff; */
  width: 100%;
  overflow-x: hidden;
  padding: 20px;
  padding-left: 0;
  background: var(--cardBg);
}
#wrapper #content-wrapper #content {
  flex: 1 0 auto;
  background: url(../../images/gridBg.png);
  background-size: cover;
  z-index: 0;
  border-radius: 20px;
  background-color: #111525;
}
.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.align-items-center {
  align-items: center !important;
}
.text-xs {
  font-size: 0.7rem;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text-primary {
  color: #4e73df !important;
}
.text-gray-800 {
  color: #5a5c69 !important;
}
.text-gray-300 {
  color: #dddfeb !important;
}
.dropdown .dropdown-menu {
  font-size: 0.85rem;
}
.dropdown .dropdown-menu .dropdown-header {
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}
.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}

.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  height: 100%;
  background-color: var(--themeBg) !important;
}
a:focus {
  outline: 0;
}

.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
}
.scroll-to-top:focus,
.scroll-to-top:hover {
  color: #fff;
}
.scroll-to-top:hover {
  background: #5a5c69;
}
.scroll-to-top i {
  font-weight: 800;
}
@-webkit-keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.animated--grow-in,
.sidebar .nav-item .collapse {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animated--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}
.text-gray-400 {
  color: #d1d3e2 !important;
}

.animate-tmp {
  animation: fadeInnnn 0.5s ease-in;
}

@keyframes fadeInnnn {
  from {
    transform: translate3d(-250px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* global end */

.verticalCenterNoData {
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: center;
}
/* sidebar and navbar css */

.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}
.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f107";
}
.nav-link {
  cursor: pointer;
}
.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}
.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.25rem;
  margin-top: -0.25rem;
}
.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile img {
  width: 100%;
  min-height: 100%;
  height: auto;
  object-fit: cover;
}
.topbar .navHead {
  font-size: 24px;
  font-family: jetbrain-mono, serif;
  color: #fff;
  margin-left: 30px;
}
.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
  overflow: hidden;
  border-radius: 50%;
}
.topbar {
  /* height: 4.375rem; */
  /* background: #1F1F1F; */
  /* background: var(--themeBg); */
  background: transparent;
}
.topbar .navBalanceRow {
  display: flex;
  align-items: center;
}
.topbar .navBalanceRow .priceCol:first-child {
  padding-right: 40px;
}
.topbar .navBalanceRow .priceCol {
  padding: 10px 20px;
  border-radius: 30px;
  margin-right: 10px;
}
.topbar .navBalanceRow .priceCol span {
  font-size: 10px;
}
.topbar .navBalanceRow .priceCol .title {
  font-size: 12px;
  color: #ddd;
  font-weight: 100;
}
.themeBg {
  background: var(--themeBg);
}
.cardBg {
  background: var(--cardBg);
}
.colorRed {
  color: #fc0606;
}
.colorGreen {
  color: #67e1ae;
}
.topbar .navBalanceRow .priceCol .price {
  font-size: 16px;
  color: #fff;
}
.topbar #sidebarToggleTop {
  height: 2.5rem;
  width: 2.5rem;
  color: var(--themeColor) !important;
}
.topbar #sidebarToggleTop:hover {
  background-color: #eaecf4;
}
.topbar #sidebarToggleTop:active {
  background-color: #dddfeb;
}
.topbar .navbar-search {
  width: 25rem;
}
.topbar .navbar-search input {
  font-size: 0.85rem;
  height: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}
.topbar .nav-item .nav-link {
  /* height: 4.375rem; */
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}
.topbar .nav-item .nav-link:focus {
  outline: 0;
}
.topbar .nav-item:focus {
  outline: 0;
}
.topbar .dropdown .profile-name {
  text-transform: capitalize;
}
.topbar .dropdown {
  position: static;
}
.topbar .dropdown .dropdown-menu .dropdown-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.topbar .dropdown .dropdown-menu .dropdown-item:hover svg path {
  stroke: #212529;
}
.topbar .dropdown .dropdown-menu .dropdown-item:hover svg {
  color: #212529;
  fill: #212529;
}
.topbar .dropdown .dropdown-menu .dropdown-item svg {
  width: 20px;
}
.topbar .dropdown .dropdown-menu .dropdown-item:hover {
  color: #212529;
}
.topbar .dropdown .dropdown-menu .dropdown-item {
  color: #fff;
  font-size: 15px;
}
.dib {
  display: inline-block;
}
.topbar .dropdown .dropdown-menu {
  width: calc(100% - 1.5rem);
  right: 0.75rem;
  min-width: 240px;
  width: 100%;
  /* background-color: rgb(55 93 114 / 33%); */
  background-color: transparent;
  backdrop-filter: blur(9px);
}
.topbar .dropdown-list {
  padding: 0;
  border: none;
  overflow: hidden;
}
.topbar .dropdown-list .dropdown-header {
  background-color: #4e73df;
  border: 1px solid #4e73df;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
}
.topbar .dropdown-list .dropdown-item {
  white-space: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: 1px solid #e3e6f0;
  border-right: 1px solid #e3e6f0;
  border-bottom: 1px solid #e3e6f0;
  line-height: 1.3rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
  background-color: #eaecf4;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0.125rem solid #fff;
}
.topbar .dropdown-list .dropdown-item .text-truncate {
  max-width: 10rem;
}
/* .topbar .dropdown-list .dropdown-item:active {
    background-color: #eaecf4;
    color: #3a3b45;
 } */

.sitenavbar {
  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  /* background: rgba( 205, 255, 255, 0.25 ); */
  /* background: #040F28!important; */
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.sitenavbar .contactNav {
  background: var(--themeColor);
  border: 2px solid var(--themeColor);
  color: white;
  /* height: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  width: 150px;
  /* width: 100%; */
  text-decoration: none;
  border-radius: 25px;
  cursor: pointer;
}
.sitenavbar .contactNav:hover {
  color: #000 !important;
}

.sitenavbar .nav-item a {
  color: white;
  font-weight: 500;
  padding: 10px;
  margin: 0 10px;
  position: relative;
}
.sitenavbar .nav-item a:hover {
  color: var(--themeColor);
}
.sitenavbar .nav-item a:hover::after {
  content: "";
  height: 2px;
  width: 40px;
  background-color: var(--themeColor);
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 0;
}
.navcont {
  /* width: 35%; */
  display: flex;
  justify-content: space-between;
}

.sitenavbar.downNav {
  background: black;
}
.sitenavbar.downNav .nav-item a {
  color: #fff;
}
.sitenavbar.downNav .nav-item a:hover {
  color: var(--themeColor);
}
.sitenavbar {
  height: auto;
}
.navbar.sitenavbar .nav-item a {
  color: white;
  font-weight: 500;
  padding: 10px;
  margin: 0 10px;
  position: relative;
}
.navbar.sitenavbar .nav-item a:hover {
  color: var(--themeColor);
}
.navbar.sitenavbar .nav-item a:hover::after {
  content: "";
  height: 2px;
  width: 40px;
  background-color: var(--themeColor);
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 0;
}
.navbar.sitenavbar .nav-item a {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
.dNone-lg {
  display: flex;
}
.dFlex-lg {
  display: none;
}
.pumplogonav {
  padding-top: 5px;
  height: 80px;
  padding: 7px;
}
.navbar.sitenavbar .navbar-toggler-icon {
  filter: invert(1);
}

.contactModal {
  /* padding: 0 !important; */
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.contactModal .heading h2 {
  color: black;
  font-weight: 700;
}
.contactModal .heading i {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.contactModal .heading {
  /* background: url(./images/contactBg.webp); */
  background-size: cover;
  background-position: right;
  padding: 30px 30px 10px 30px;
}
.contactModal .mainContent {
  padding: 25px;
  /* background-color: #fef8f5; */
  border-radius: 12px;
  box-shadow: 0px 1px 7px 0px rgb(0 0 0 / 25%);
}
.contactModal .mainContent .contact {
  font-size: 30px;
  color: black;
  line-height: 1.2;
}

.contactModal .mainContent .socialMain {
  text-align: center;
  margin: 20px 0;
}
.contactModal .mainContent .socialMain a {
  text-decoration: none;
  color: transparent;
}
.contactModal .mainContent i {
  padding: 0px 10px;
}

.contactModal .mainContent .fb {
  color: #fb5b21;
}
.contactModal .mainContent .fb:hover {
  color: #4867aa;
  cursor: pointer;
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4); */
  transition: all ease-in-out 150ms;
}
.contactModal .mainContent label {
  /* font-size: 13px; */
  margin-bottom: 6px;
  color: #3c4253;
  opacity: 0.4;
  font-weight: 600;
  font-size: 12px;
}
.contactModal .modalImg {
  margin-bottom: 20px;
}

.contactModal .mainContent .twitter {
  color: #fb5b21;
  cursor: pointer;
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4); */
  transition: all ease-in-out 150ms;
}

.contactModal .mainContent .twitter:hover {
  color: #1da1f2;
  /* margin-top: -10px; */
  /* text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3); */
  /* transform: translate(0, -8); */
}

.contactModal .mainContent .git {
  transition: all ease-in-out 150ms;
  color: transparent;
  background: #fb5b21;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: bold;
}

.contactModal .mainContent .git:hover {
  background: -webkit-radial-gradient(
    30% 107%,
    circle,
    #ad9b35 0%,
    #cfbb45 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -o-radial-gradient(
    30% 107%,
    circle,
    #ad9b35 0%,
    #cfbb45 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: radial-gradient(
    circle at 30% 107%,
    #ad9b35 0%,
    #cfbb45 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #ad9b35 0%,
    #cfbb45 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* margin-top: -10px; */
  /* text-shadow: 0px 14px 10px rgba(0, 0, 0, 0.4); */
  /* transform: translate(0, -5); */
}
.contactModal .mainContent i:nth-child(4) {
  color: #fffc00;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
}
.contactModal .mainContent i:nth-child(4):hover {
  margin-top: -10px;
  text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3);
  transform: translate(0, -8);
}

.contactModal .mainContent .contentDiv {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.contactModal .mainContent .contentDiv .imgDiv {
  margin-right: 8px;
}
.contactModal .mainContent .contentDiv .imgDiv i {
  font-size: 25px;
  color: #fb5b21;
}
.contactModal .mainContent .contentDiv .imgDiv img {
  width: 25px;
}
.contactModal .mainContent .contentDiv .contactDiv p {
  color: #707070;
  font-size: 13px;
  margin: 0;
}
.contactModal .mainContent .contentDiv .contactDiv a {
  color: #fb5b21;
  font-weight: bold;
}
.contactModal .form-control {
  border: 1px solid #fff1cc;
  border-radius: 8px;
  min-height: 38px;
}
.contactModal input {
  color: #707070;
  font-size: 13px;
  font-weight: 500;
}
.contactModal textarea::placeholder {
  color: #707070;
  font-size: 13px;
  font-weight: 500;
}
.contactModal .sendBtn {
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: white;
  font-weight: 500;
  padding: 8px 38px;
  border-radius: 20px;
}
.contactModal .form-group {
  margin-bottom: 12px;
}
.contactMM .contactModal .mainContent {
  box-shadow: none;
}
.loginPage .contactModal {
  min-height: calc(100vh - 80px);
}
.loginPage {
  min-height: 100vh;
  padding: 50px 0;
  display: flex;
  align-items: center;
  /* background: url(../../images/webp/gridBg.webp); */
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.loginPage .backStyle {
  padding: 30px;
  min-height: 100vh;
}
.loginPage .logoDiv {
  cursor: pointer;
  width: 129px;
  padding: 10px;
}
.loginPage .logoDiv img {
  max-width: 100%;
}
.loginPage .artDiv {
  /* width: 60%; */
  margin: auto;
  max-width: 570px;
}
.loginPage .artDiv img {
  max-width: 100%;
  position: relative;
  top: 0;
  animation: updown1 3s linear infinite;
}

.ai-center {
  align-items: center;
}
.loginPage .loginForm .formBorder img {
  width: 100%;
  height: 100%;
}
.loginPage .loginForm .formBorder {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.loginPage .loginForm {
  position: relative;
  padding: 35px;
  max-width: 500px;
  margin: 0 auto;
  /* min-height: 550px; */
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #3c3c3c;
  background: var(--cardBg);
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
}
.loginPage .loginForm .signBtn {
  text-align: center;
  /* max-width: 185px;
   margin: auto; */
  /* cursor: pointer; */
  margin-top: 35px;
}
.loginPage .loginForm .inputBorder {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 55px;
}
input:focus {
  outline: none;
  box-shadow: none;
}
.loginPage .loginForm .form-group {
  position: relative;
}
.loginPage .loginForm .innerForm {
  /* max-width: 370px; */
  margin: auto;
  width: 100%;
}
.hrLine {
  border-bottom: 1px solid #3c3c3c;
  width: 100%;
  position: absolute;
  left: 0;
}
.themeCardGlobal {
  border-radius: 13px;
  border: 1px solid #3c3c3c;
  /* background: var(--s, linear-gradient(176deg, #201F21 -2.43%, rgba(32, 31, 33, 0.00) 129.1%)); */
  background: var(--cardBg);
}
.fs-18 {
  font-size: 18px;
}
.loginPage .loginForm h1 {
  font-family: desirableSans;
  text-transform: uppercase;
  margin-bottom: 18px;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 1px;
  color: var(--themeGreen);
}
.loginPage .loginForm a:hover {
  text-decoration: none;
}
.loginPage .loginForm a {
  color: var(--themeGreen);
}
.loginPage .loginForm .notReg {
  /* font-family: 'jetbrain-mono',serif; */
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 25px;
}

.loginPage .loginForm input::placeholder,
.loginPage .loginForm .input::placeholder {
  color: #fff;
}
.loginPage .loginForm input[type="checkbox"],
.loginPage .loginForm .input[type="checkbox"] {
  height: auto;
}
input::file-selector-button {
  background-color: var(--themeGreen);
  border: none;
  border-radius: 25px;
  padding: 8px 15px;
  font-weight: bold;
}
.kycModal1 .idType {
  font-family: "titillium-web", serif;
  border: none;
  background: transparent;
  font-size: 18px;
  outline: none;
  /* color: #56687F; */
  color: #fff;
  background: var(--themeBg);
}
.kycModal1 .inputFile {
  border-radius: 50px;
  font-family: "titillium-web", serif;
  border: none;
  background: transparent;
  padding: 13px 30px;
  font-size: 18px;
  outline: none;
  /* color: #56687F; */
  color: #fff;
  background: var(--themeBg);
}

.kycModal1 input::placeholder {
  /* color: #fff; */
}
.kycModal1 .css-cgt4s4-control {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}
.kycModal1 .css-ytvn0b-control {
  border: none;
  outline: none;
  box-shadow: none;
}
.kycModal1 .input {
  font-family: "titillium-web", serif;
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 13px 30px;
  font-size: 18px;
  height: 55px;
  outline: none;
  /* color: #56687F; */
  color: #fff;
  border-radius: 30px;
  background: var(--themeBg);
}
.kycModal1 textarea {
  font-family: "titillium-web", serif;
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 13px 30px;
  font-size: 18px;
  outline: none;
  /* color: #56687F; */
  color: #fff;
  border-radius: 30px;
  background: var(--themeBg);
}
.br-0 {
  border-radius: 0 !important;
}
.loginPage .loginForm input,
.loginPage .loginForm .input,
.loginPage .loginForm .PhoneInput {
  font-family: "Space Grotesk", serif;
  border: none;
  background: transparent;
  padding: 13px 25px;
  font-size: 18px;
  height: 55px;
  outline: none;
  /* color: #56687F; */
  color: #fff;
  border-radius: 30px;
  background: var(--themeBg);
}
.loginPage .loginForm select {
  color: #fff !important;
}
.loginPage .loginForm select option {
  background: #1b164b;
  color: #bebebe;
}
select option[value=""] {
  color: #999999 !important;
}

.loginPage .loginForm .themeBtn {
  width: 100%;
  margin-top: 30px;
  font-size: 20px;
  padding: 16px 25px;
}
.loginPage .loginForm .forgetPass a {
  /* font-family: 'jetbrain-mono',serif; */
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.loginPage .loginForm .forgetPass {
  text-align: right;
}
.loginPage .loginForm a {
  /* font-weight: 500; */
  font-size: 20px;
}
.loginPage .terms span {
  cursor: pointer;
}
.loginPage .terms {
  color: #bebebe;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 6px;
}
.modal-content {
  /* background-color: rgba( 13, 39, 71, 0.9 ) !important; */
  /* background-color: transparent !important; */
  color: white;
  border-radius: 15px;
  border: 1px solid #3c3c3c !important;
  /* background: linear-gradient(174deg, #080808 -1.2%, rgba(32, 31, 33, 0.00) 380.04%) !important; */
  /* background: rgba(26, 26, 26, 1) !important; */
  background: var(--cardBg) !important;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05) !important;
}
.conditionModal .modal-dialog {
  max-width: 900px !important;
}
.conditionModal .conditionMain h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
.conditionModal .conditionMain {
  background: var(--themeBg1);
  padding: 20px;
  border-radius: 8px;
  margin: 0 40px;
  height: 380px;
  overflow-y: auto;
}
.conditionModal .btnMain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.conditionModal button {
  max-width: 150px;
  width: 100%;
}
.conditionModal .outlineBtn {
  background: transparent;
  border: 1px solid #fff;
  padding: 8px 25px;
  color: white;
  font-weight: 500;
  border-radius: 7px;
}
.editApiModal .btnMain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.editApiModal .button {
  max-width: 150px;
  width: 100%;
}
.editApiModal button {
  max-width: 150px;
  width: 100%;
}
.editApiModal .outlineBtn:hover {
  background: var(--themeGreen);
  text-decoration: none;
  color: #fff;
}
.editApiModal .outlineBtn {
  background: transparent;
  border: 1px solid var(--themeGreen);
  padding: 8px 25px;
  color: var(--themeGreen);
  font-weight: 500;
  border-radius: 7px;
}
.editApiModal input {
  background: var(--themeBg);
  border-radius: 8px;
  border: 1px solid #3c3c3c;
  padding: 13px;
  font-size: 18px;
  height: 50px;
  outline: none;
  /* color: #56687F; */
  color: #fff;
  width: 100%;
}

.conditionModal .conditionMain::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--themeBg1);
}

.conditionModal .conditionMain::-webkit-scrollbar {
  width: 6px;
  background-color: var(--themeBg1);
}

.conditionModal .conditionMain::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--themeGreen);
}
.modal {
  backdrop-filter: blur(10px);
}
.theme-blueBg {
  background: var(--themeBlue);
}
.bg-dull {
  background: rgba(242, 242, 242, 0.3) !important;
}
.theme-blueColor {
  color: var(--Gradient);
}

@media (max-width: 992px) {
  .navcont {
    align-items: flex-start !important;
  }
  .navcont li {
    display: inherit;
  }
  .pumplogonav {
    height: 70px;
  }
  .balanceBar.b1 .balanceBtn {
    min-width: unset !important;
  }
}
@media (min-width: 991.98px) {
  .demoModal .modal-dialog {
    max-width: 70% !important;
  }
  .credit {
    background: var(--themeColor);
    margin-right: -3rem;
  }
  .sitenavbar {
    height: 80px;
  }
  .sitenavbar .contactNav {
    /* margin-right: -16px !important; */
  }
  .dNone-lg {
    display: none !important;
  }
  .dFlex-lg {
    display: flex;
  }
}
@media (min-width: 576px) {
  .topbar.navbar {
    /* position: absolute;
      top: 12px;
      right: 0;
      width: max-content; */
    z-index: 2;
  }
  .topbar .dropdown {
    position: relative;
  }
  .topbar .dropdown .dropdown-menu {
    width: auto;
    right: 0;
  }
  .topbar .dropdown-list {
    width: 20rem !important;
  }
  .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
  }
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: #fff;
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link:active {
  color: #fff;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #d1d3e2;
  background-color: var(--cardBg);
  padding: 7px 25px;
  border-radius: 25px;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #b7b9cc;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
  color: #858796;
}
.sidebar {
  width: 6.5rem;
  min-height: 100vh;
  /* background: #F7F8FC; */
  background: var(--cardBg);
  /* font-family: jetbrain-mono; */
  position: relative;
}
.sidebar .nav-item {
  position: relative;
}
.sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}
.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.3rem 1rem;
  width: 6.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sidebar .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}
.sidebar .nav-item .nav-link .downCircle {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #e0262a;
  border-radius: 50%;
  margin-right: 5px;
}

.sidebar .nav-item .collapse {
  position: absolute;
  left: calc(5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
}
.sidebar .nav-item .collapse .collapse-inner {
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}
.sidebar .nav-item .collapsing {
  display: none;
  transition: none;
}
.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
  padding: 0.5rem 0;
  min-width: 10rem;
  font-size: 0.85rem;
  margin: 0 0 1rem 0;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #eaecf4;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
  background-color: #dddfeb;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #4e73df;
  font-weight: 700;
}
.sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}
.sidebar #sidebarToggle::after {
  font-weight: 900;
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  margin-right: 0.1rem;
}
.sidebar #sidebarToggle:hover {
  text-decoration: none;
}
.sidebar #sidebarToggle:focus {
  outline: 0;
}
.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}
.sidebar.toggled #sidebarToggle::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  margin-left: 0.25rem;
}
.sidebar.toggled .sidebar-card {
  display: none;
}
.sidebar .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 0rem 1rem;
  /* text-align: center; */
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
  padding-left: 35px;
}
.sidebar .sidebar-brand .sidebar-brand-icon i {
  font-size: 2rem;
}
.sidebar .sidebar-brand .sidebar-brand-text img {
  max-height: 100% !important;
}
.sidebar .sidebar-brand .sidebar-brand-text {
  /* display: none; */
  height: 100%;
  font-weight: 400;
  font-family: revert;
  text-transform: capitalize;
  font-family: desirableSans, serif;
  font-size: 28px;
  margin-top: 19px;
  margin-left: 9px;
}
.sidebar hr.sidebar-divider {
  margin: 0 1rem 1rem;
}
.sidebar .sidebar-heading {
  text-align: center;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}
.sidebar .sidebar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 0.35rem;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar .sidebar-card .sidebar-card-illustration {
  height: 3rem;
  display: block;
}
.sidebar .sidebar-card .sidebar-card-title {
  font-weight: 700;
}
.sidebar .sidebar-card p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}
.sidebar .leftbarImg img {
  /* width: 100%; */
  /* height: 100%; */
  /* margin-top: 75px;
   height: 450px; */
  margin-top: 45px;
  height: 540px;
  width: 55px;
  position: relative;
  left: 10px;
  filter: invert(1) brightness(0);
}
.sidebar .leftbarImg {
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0;
  width: 61px;
  background: #1a1a1a;
}
.css-1gv0vcd-MuiSlider-track {
  border: 1px solid var(--themeColor) !important;
  background-color: var(--themeColor) !important;
}
.css-eg0mwd-MuiSlider-thumb {
  background-color: var(--themeColor) !important;
}
.css-14pt78w-MuiSlider-rail {
  background-color: var(--themeColor) !important;
}

.rightSidebar {
  /* background: #F7F8FC; */
  /* background: var(--themeBg); */
  /* padding: 16px; */
  /* padding-top: 100px; */
  /* min-height: 100vh; */
  color: white;
}
.rightSidebar .card {
  background: rgba(0, 0, 0, 0.2);
}
.rightSidebar .card-header {
  background: #f7f8fc !important;
}
.gray-color {
  color: #a6a9b7;
}
.rightSidebar .depositBtnMain {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.rightSidebar .depositBtnMain .depositBtn {
  background-color: var(--themeGreen);
  border: 2px solid var(--themeGreen);
  border-radius: 4px;
  padding: 6px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-right: 6px;
}
.rightSidebar .depositBtnMain .depositBtn:hover {
  background-color: #fff;
  color: var(--themeGreen);
}
.rightSidebar .depositBtnMain .veiwBtn:hover {
  background-color: var(--themeGreen);
  border: 2px solid var(--themeGreen);
  color: #fff;
}
.rightSidebar .depositBtnMain .veiwBtn {
  background-color: #fff;
  border: 2px solid var(--themeGreen);
  border-radius: 4px;
  padding: 6px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--themeGreen);
}

.balanceBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
}
.balanceBar.b1 .balanceBtn {
  min-width: 170px;
  width: max-content;
  max-width: 100%;
  cursor: pointer;
}
.balanceBar .balanceBtn {
  max-width: 170px;
}
.balanceBtn {
  background: var(--Gradient);
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0% 50%);
  padding: 1px;
  font-family: jetbrain-Bold;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.balanceBtn .innerBtn {
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0% 50%);
  background: radial-gradient(
    97.13% 43.65% at 50.57% 112.48%,
    #45329c 0%,
    #090040 92%
  );
  height: 43px;
  padding: 8px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.balanceBtn .innerBtn img {
  width: 24px;
  margin-right: 7px;
}
.connectModal .balanceBtn .innerBtn {
  height: auto;
  cursor: pointer;
}
.connectModal .balanceBtn .innerBtn img {
  width: auto;
  height: 14px;
}

.topbar .profileDrop {
  padding: 15px;
  position: relative;
  padding-bottom: 30px;
  padding-top: 25px;
}
.topbar .profileNav {
  background: var(--themeGreen);
  padding: 1px;
  clip-path: polygon(10% 0, 100% 0, 100% 60%, 90% 100%, 0 100%, 0 40%);
}
.topbar .profileNav .inner {
  background: var(
    --Linear-grad,
    linear-gradient(180deg, #090040 0%, #45329c 100%)
  );
  display: flex;
  align-items: center;
  clip-path: polygon(10% 0, 100% 0, 100% 60%, 90% 100%, 0 100%, 0 40%);
}
.topbar .profileNav .inner .notifyIco {
  padding: 10px 15px;
  border-right: 1px solid var(--themeGreen);
  cursor: pointer;
}
.topbar .profileNav .inner .name {
  padding: 10px 15px;
  font-family: jetbrain-Bold;
  color: var(--themeGreen);
  font-size: 20px;
  cursor: pointer;
}
.leftCutBorder {
  padding: 1px;
  background: var(--themeBlue);
  clip-path: polygon(6% 0, 100% 0, 100% 70%, 94% 100%, 0 100%, 0 34%);
}

.seekoLoader {
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1111;
}
.seekoLoader .loading {
  width: 100%;
  max-width: 250px;
  padding: 40px 0;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cardBg);
  border-radius: 20px;
  position: fixed;
}

.font-jetbrainBold {
  font-family: jetbrain-Bold;
}

.seekoLoader img {
  width: 90px;
  animation: beat 0.4s infinite alternate;
}
/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.3);
  }
}

.themeColor {
  color: var(--themeGreen);
}
a.themeColor:hover {
  color: var(--themeGreen) !important;
}
.notApproveYet {
  padding: 30px;
  border: 1px solid #3c3c3c;
  background: var(--cardBg);
  border-radius: 15px;
  position: relative;
  align-items: center;
}
.notApproveYet p {
  /* font-family: SpaceGrotesk; */
  font-size: 17px;
  margin: 25px 0;
}

.sidebar-light .sidebar-brand {
  color: #6e707e;
}
.sidebar-light hr.sidebar-divider {
  border-top: 1px solid #eaecf4;
}
.sidebar-light .sidebar-heading {
  color: #b7b9cc;
}
.sidebar-light .nav-item .nav-link {
  color: #858796;
}
.sidebar-light .nav-item .nav-link i {
  color: #d1d3e2;
}
.sidebar-light .nav-item .nav-link:active,
.sidebar-light .nav-item .nav-link:focus,
.sidebar-light .nav-item .nav-link:hover {
  color: #6e707e;
}
.sidebar-light .nav-item .nav-link:active i,
.sidebar-light .nav-item .nav-link:focus i,
.sidebar-light .nav-item .nav-link:hover i {
  color: #6e707e;
}
.sidebar-light .nav-item .nav-link[data-toggle="collapse"]::after {
  color: #b7b9cc;
}
.sidebar-light .nav-item.active .nav-link {
  color: #6e707e;
}
.sidebar-light .nav-item.active .nav-link i {
  color: #6e707e;
}
.sidebar-light #sidebarToggle {
  background-color: #eaecf4;
}
.sidebar-light #sidebarToggle::after {
  color: #b7b9cc;
}
.sidebar-light #sidebarToggle:hover {
  background-color: #dddfeb;
}
.sidebar-dark .sidebar-brand {
  color: #ddd;
  margin-top: 20px;
}
.sidebar-dark hr.sidebar-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.sidebar-dark .sidebar-heading {
  color: rgba(255, 255, 255, 0.4);
}
.sidebar-dark .nav-item .nav-link {
  color: #ddd;
  margin: auto;
  font-weight: 500;
  width: 90%;
  /* border-radius: 10px; */
}
.sidebar-dark .nav-item .nav-link i {
  color: #8f9fb4;
}
.sidebar-dark .nav-item .nav-link img {
  color: #56687f;
  width: 16px;
}
/* .sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
    color: #fff;
    background-image: linear-gradient(to right, rgb(255 0 247), rgb(82 12 90));

 } */
.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
  color: #fff;
}
.sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark .nav-item.active .nav-link {
  color: #8f9fb4;

  background-image: linear-gradient(to right, rgb(255 0 247), rgb(82 12 90));
  margin: auto;
  width: 90%;
  color: #f5f8fd;
  border-radius: 10px;
}
.sidebar-dark .nav-item.active .nav-link i {
  color: #8f9fb4;
}
.sidebar-dark #sidebarToggle {
  background-color: rgba(255, 255, 255, 0.2);
}
.sidebar-dark #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark #sidebarToggle:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.sidebar-dark.toggled #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}
.fw-500 {
  font-weight: 500;
}
.theme-bg-color {
  background-color: #fbf6f0 !important;
}
.jc-sb {
  justify-content: space-between;
}
.search-btn {
  background-color: #fbf6f0 !important;
  border-color: #fbf6f0 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.w-15 {
  width: 15px;
}
.profile-name {
  font-family: tt-firs-bold, serif;
  color: #fff;
  /* font-weight: 700 !important; */
  font-size: 18px;
  display: block;
}
.status {
  font-size: 12px;
  color: #56687f;
  font-weight: 600;
}
.profile-div {
  line-height: 1;
}
.w-9 {
  width: 10px;
}
.zi-1 {
  z-index: 1;
}
.logout-li {
  position: absolute;
  bottom: 30px;
}
.logout-div {
  display: flex !important;
  flex-direction: row !important;
  padding-left: 1rem;
}
.logout-div .img-profile {
  width: 2rem;
  height: 2rem;
  margin-right: 10px;
}
.text-align-left {
  text-align: left !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.jc-center {
  justify-content: center;
}
/* .sidebar-Link.active svg {
      box-shadow: 0 0 4px 1px blue;
   }
   .sidebar-Link svg {
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.3);
   } */
.sidebar-Link.active .icon {
  /* box-shadow: -2px 0 4px 0px #DB7AFF, 1px 0 4px 1px #5282FF; */
  border: 1px solid var(--themeGreen);
}

.sidebar-Link .icon {
  position: relative;
  width: 37px;
  height: 37px;
  padding: 6px;
  border-radius: 50%;
  /* border: 1px solid rgba(255,255,255,0.3); */
  /* background: rgba(0,0,0,0.3); */
  margin: 0 !important;
  display: inline-block !important;
}
.sidebar-Link svg,
.sidebar-Link svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
}
.sidebar-Link.active.setting svg,
.sidebar-Link.active.setting svg path,
.sidebar-Link.active.setting svg rect {
  fill: none;
}
.sidebar-Link.active svg,
.sidebar-Link.active svg path,
.sidebar-Link.active svg rect {
  /* fill: var(--themeGreen); */
  fill: #121318;
  stroke: #121318;
}
.sidebar-Link.active {
  /* color: var(--themeGreen) !important; */
  color: #121318 !important;
  font-weight: bold !important;
  background-color: var(--themeGreen);
  border-radius: 25px;
  /* border-right: 4px solid var(--Gradient); */
  /* background-image: linear-gradient(to right, #e0262a, rgb(243 222 0)) !important; */
}
.ai-center {
  align-items: center;
}
.br10 {
  border-radius: 10px !important;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 13px;
}
.fs-15 {
  font-size: 13px;
}
.fs-15 {
  font-size: 13px;
}
.modalForm {
  margin: 10px 0;
  position: relative;
}
.modalForm h3 {
  text-align: center;
  margin-bottom: 20px;
}
.modalForm .subBtn {
  margin-top: 30px;
}
.modalForm label {
  color: #3c4253;
  opacity: 0.4;
  font-weight: 600;
  font-size: 12px;
}
.modalForm .closeIco {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.modal-content {
  border-radius: 15px !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgb(5 180 239 / 15%) !important;
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgb(5 180 239 / 15%) !important;
}
.modalForm ::placeholder {
  color: #8f9fb4;
  opacity: 0.65;
}
.footer {
  text-align: center;
  background-color: black;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.footer {
  text-align: left;
}
.footer p {
  color: #bdbdbf;
}
.footer .text-primary {
  color: #fb5b21 !important;
}
.footer a:hover {
  color: white;
}
.footer a {
  text-decoration: none;
  color: #bdbdbf;
}
.footer .text-secondry:hover {
  color: #fff;
}
.footer .text-secondry:hover {
  color: #fff;
}
.footer .text-secondary {
  color: #bdbdbf !important;
  text-decoration: none;
}
.footer .bg-primary {
  background-color: var(--themeColor) !important;
}
.footer .btn-primary {
  color: white;
  background: var(--themeColor);
  border-color: var(--themeColor);
}
.footer .btn-primary:hover {
  color: black;
  background: var(--themeColor);
  border-color: var(--themeColor);
}

.footer .fMain {
  padding-left: 3rem;
  padding-right: 3rem;
}
.footer .newslMain {
  padding: 3rem;
}
.footer .pumplogo {
  height: 100px;
}
.userHrDetail .name {
  font-weight: 500;
}
.userHrDetail .logoDiv {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.userHrDetail .logoDiv img {
  max-width: 100%;
}
.userHrDetail .content {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid rgb(211, 211, 211);
}
.nodataModal .crossIC {
  color: var(--themeColor);
  font-size: 60px;
  margin-bottom: 20px;
}
.otpModalMain {
  padding: 10px;
  position: relative;
}
.otpModalMain .closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}

.otpModalMain .logo {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 20px;
}
.otpModalMain .title {
  color: var(--themeGreen);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.otpModalMain .desc {
  color: #efecec;
  margin-bottom: 30px;
  text-align: center;
}
.otpModalMain .desc a {
  color: rgba(4, 165, 94, 1);
}
.otpModalMain .resend {
  color: #024527;
  text-align: center;
  margin: 25px 0;
}
.otpModalMain .resend span {
  color: #04a55e;
}
.otpModalMain .contBtn {
  color: white;
  font-size: 18px;
  width: 100%;
  border: 1px solid rgba(4, 165, 94, 1);
  border-radius: 10px;
  background: rgba(4, 165, 94, 1);
  height: 50px;
}
.otpModalMain .otpInputMain {
  display: flex;
  justify-content: center;
}
.otpModalMain .otpInputMain input:not(:empty) {
  background-color: #fff;
}
.otpModalMain .otpInputMain input {
  background-color: var(--themeBg);
  /* background-color: rgba(8, 8, 8, 1); */
  color: var(--themeGreen);
  border: 1px solid #3c3c3c;
  border-radius: 7px;
  width: 63px !important;
  height: 70px;
  font-size: 34px !important;
}

.otpModalMain .otpInputMain input:focus {
  border: 1px solid #04a55e !important;
  box-shadow: 0px 0px 0px 3px rgba(4, 165, 94, 0.12);
  outline: none !important;
}
.modal-body {
  padding: 30px !important;
}
.modal-body .modalBorder {
  position: absolute;
  /* z-index: -1; */
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
.modal-body .submitBtn {
  text-align: center;
  cursor: pointer;
  /* max-width: 212px; */
  /* width: 100%; */
  /* margin: auto; */
  margin-top: 40px;
}
.theme-color {
  color: var(--themeGreen);
}
.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto !important;
    width: 41.66666667% !important;
  }
  .col-xxl-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
  .col-xxl-7 {
    flex: 0 0 auto !important;
    width: 58.33333333% !important;
    max-width: 58.33333333% !important;
  }
  .col-xxl-8 {
    flex: 0 0 auto !important;
    width: 66.66666667% !important;
  }
  .col-xxl-9 {
    flex: 0 0 auto !important;
    width: 75% !important;
  }
  .col-xxl-10 {
    flex: 0 0 auto !important;
    width: 83.33333333% !important;
  }
  .col-xxl-11 {
    flex: 0 0 auto !important;
    width: 91.66666667% !important;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .sidebar-dark .nav-item .nav-link {
    width: 82%;
  }
  .contactMM .modal-dialog {
    max-width: 720px !important;
  }
  .loginPage .col1 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 30px 0;
    background-color: var(--cardBg);
    position: relative;
  }
  .loginPage .col1 .logoDiv {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .loginPage .col2 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 30px 0;
  }
  .loginPage {
    padding: 0;
  }
  .loginPage .loginForm {
    flex-shrink: 0;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .sidebar {
    width: 17rem !important;
  }
  .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none;
  }
  .sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0;
    box-shadow: none;
  }
  .sidebar .nav-item .collapsing {
    display: block;
    transition: height 0.15s ease;
  }
  .sidebar .nav-item .collapse,
  .sidebar .nav-item .collapsing {
    margin: 0 1rem;
  }
  .sidebar .nav-item .nav-link {
    flex-direction: row;
    display: flex;
    width: 82%;
    text-align: left;
    /* padding: 1rem; */
  }
  .sidebar .nav-item .nav-link i {
    font-size: 0.85rem;
    margin-right: 0.25rem;
  }
  .sidebar .nav-item .nav-link span {
    font-size: 0.9rem;
    display: inline;
    margin-left: 8px;
  }
  .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
  }
  .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
    content: "\f105";
  }
  .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }
  .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline-block;
  }
  .sidebar .sidebar-heading {
    text-align: left;
  }
  .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
  }
  .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-timing-function: transform
        cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
  }
  .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 0.35rem;
  }
  .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none;
  }
  .sidebar.toggled .nav-item .collapse,
  .sidebar.toggled .nav-item .collapsing {
    margin: 0;
  }
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;

    color: #8f9fb4;
    margin: auto;
    width: 90%;
    border-radius: 10px;
  }
  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }
  .sidebar.toggled .nav-item .nav-link i {
    margin-right: 0;
  }
  .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
    display: none;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none;
  }
  .sidebar.toggled .sidebar-heading {
    text-align: center;
  }
}
@media (max-width: 992px) {
  .footer p {
    margin-bottom: 10px;
  }
  .balanceBar.b1 {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  .footer .bg-primary {
    margin-right: -3rem;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px !important;
  }
}
@media (max-width: 767px) {
  .logout-div {
    display: flex !important;
    flex-direction: column !important;
  }
  .logout-div .img-profile {
    margin: auto;
  }
  .sidebar .leftbarImg {
    width: 100%;
  }
  .sidebar .nav-item.mt-5 {
    margin-top: 0 !important;
  }
  .sidebar .sidebar-brand {
    padding-left: 1rem;
    text-align: center;
  }
  .sidebar .leftbarImg img {
    height: 690px;
    width: calc(100% - 6px);
    left: 6px;
    margin-top: 0;
  }
  #sidebarToggleTop {
    position: absolute;
    left: 32px;
    top: 20px;
    z-index: 10;
    color: white;
    border-radius: 50% !important;
    background: var(--cardBg);
  }
  .sidebar .sidebar-brand .sidebar-brand-text {
    visibility: hidden;
  }
}
@media (max-width: 576px) {
  .loginPage .artDiv {
    margin-bottom: 30px;
  }
  .footer .fMain {
    padding-left: 0;
    padding-right: 0;
  }
  .mx-xs-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .footer .newslMain {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: -24px;
    margin-left: -24px;
  }
  .loginPage .loginForm {
    padding: 50px 20px;
  }
  .loginPage .loginForm h1 {
    font-size: 35px;
  }
  .loginPage .loginForm .notReg {
    font-size: 22px;
  }
  .loginPage .loginForm a {
    font-size: 16px;
  }
  .conditionModal .conditionMain {
    margin: 0;
    padding: 20px 12px;
  }
  .otpModalMain .title {
    font-size: 26px;
  }
  .otpModalMain .closeIcon {
    top: -3px;
  }
  .topbar {
    flex-direction: column !important;
  }
  .topbar .navHead {
    order: 2;
  }
  .topbar .dropdown .dropdown-menu {
    top: 57px;
  }
  .topbar .navBalanceRow .priceCol:first-child {
    padding-right: 12px;
    margin-left: 12px;
  }
  .topbar .navBalanceRow .priceCol {
    padding: 12px 12px;
    margin-right: 0;
  }
  .topbar .navBalanceRow {
    flex-wrap: wrap;
  }
  #wrapper #content-wrapper {
    padding-right: 0;
  }
}
.div-hover:hover {
  background: var(--themeBg) !important;
}
.sidebar-item:hover {
  /* color: white !important;
   text-shadow:  4px 4px 8px white; */
}
.cardBorder {
  border: 1px solid #3c3c3c;
}

.showPassInput {
  position: relative;
}
.showPassInput img {
  width: 20px;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}
