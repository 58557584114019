.copyTradePage {
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 15px;
  color: #fff;
}
.copyTradePage .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #fff;
  font-family: desirableSans, serif;
  color: var(--themeGreen);
}
.copyTradePage .header h2 span {
  font-weight: normal;
  font-size: 24px;
}
.copyTradePage .tagLine {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 15px;
}
.copyTradePage .followProfileCard .balanceBtn .innerBtn img {
  width: 13px;
}
.copyTradePage .followProfileCard .balanceBtn .innerBtn {
  height: auto;
  cursor: pointer;
}
.copyTradePage .followProfileCard .balanceBtn {
  font-size: 12px;
}
.copyTradePage .followProfileCard {
  /* padding: 30px 15px; */
  /* border: 1px solid #E6E6E6; */
  /* background: var(--themeBg); */
  border-radius: 11px;
  height: 100%;
  color: #fff;
}
.copyTradePage .followProfileCard .r1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 25px; */
  padding: 10px;
}
.copyTradePage .followProfileCard .r1 .pMain {
  display: flex;
  align-items: center;
  height: 69px;
}
.copyTradePage .followProfileCard .r1 .pMain .imgDiv {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
  flex-shrink: 0;
}
.copyTradePage .followProfileCard .r1 .pMain .imgDiv img {
  width: 100%;
  height: auto;
}
.copyTradePage .followProfileCard .r1 .name {
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: tt-firs-bold, serif;
  letter-spacing: 1.2px;
}
.copyTradePage .followProfileCard .r1 .followers {
  font-size: 10px;
  /* color: #969696; */
  color: #bebebe;
}
.copyTradePage .followProfileCard .r1 .themeBtn {
  /* max-width: 105px; */
  /* width: 100%; */
  text-align: center;
  font-size: 12px;
  font-family: tt-firs-bold;
}

.copyTradePage .followProfileCard .chartRow {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-top: 1px solid #3c3c3c;
}
.copyTradePage .followProfileCard .chartRow .chatM {
  margin-right: 10px;
  width: 45%;
}
.copyTradePage .followProfileCard .chartRow .chatM .perc {
  font-family: SpaceGrotesk, serif;
  font-weight: 500;
  color: var(--themeGreen);
  font-size: 22px;
  line-height: 1;
}
.copyTradePage .followProfileCard .chartRow .chatM .percM p {
  font-size: 16px;
  color: #707070;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}
.copyTradePage .followProfileCard .chartRow .BalanceMain {
  border-radius: 9px;
  /* background: #F7F8FC; */
  /* background: radial-gradient(72.04% 40.67% at 50.51% 49.88%, #45329C 0%, #090040 92%); */
  /* background: linear-gradient(170deg, #201F21 -2.91%, rgba(114, 114, 114, 0.00) 125.73%); */
  /* background: #1A1A1A; */
  background: var(--themeBg);
  padding: 10px;
  width: 40%;
  color: #fff;
  position: relative;
  border: 1px solid #3c3c3c;
}
.copyTradePage .followProfileCard .chartRow .BalanceMain .balanceM:last-child {
  margin-bottom: 0;
}
.copyTradePage .followProfileCard .chartRow .BalanceMain .balanceM {
  margin-bottom: 10px;
}
.copyTradePage .followProfileCard .chartRow .BalanceMain .balanceM .balance {
  font-size: 14px;
  font-weight: 700;
}
.copyTradePage .followProfileCard .chartRow .BalanceMain .balanceM .source {
  /* color: #969696; */
  color: var(--themeGreen);
  font-size: 8px;
}
.copyTradePage .dChartCard {
  padding: 15px;
  /* border: 1px solid #E6E6E6; */
  background: var(--themeBg);
  border-radius: 11px;
  color: white;
}
.copyTradePage .dChartCard .r1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.copyTradePage .dChartCard .r1 .title {
  font-size: 14px;
  font-weight: 500;
}
.copyTradePage .dChartCard .r1 select {
  min-width: 55px;
  /* background: #F7F8FC; */
  background: rgba(247, 248, 252, 0.1);
  border: none;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 11px;
  color: #bebebe;
}
.copyTradePage .dChartCard .r2 {
  padding: 5px;
  display: flex;
}
.copyTradePage .dChartCard .r2 .chartM {
  width: 60%;
  max-width: 270px;
}
.copyTradePage .dChartCard .r2 .pairMain {
  margin-left: 10px;
}
.copyTradePage .dChartCard .r2 .pairMain .pairRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.copyTradePage .dChartCard .r2 .pairMain .pairRow .color {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 10px;
}
.copyTradePage .dChartCard .r2 .pairMain .pairRow .color.blue {
  background: #05b4ef;
}
.copyTradePage .dChartCard .r2 .pairMain .pairRow .color.green {
  background: #1abc85;
}
.copyTradePage .dChartCard .r2 .pairMain .pairRow .color.red {
  background: #e6496a;
}
.copyTradePage .dChartCard .r2 .pairMain .pairRow .pair {
  font-size: 13px;
  text-transform: uppercase;
  /* color: #707070; */
  color: #fff;
  font-weight: 500;
}
.copyTradePage .dChartCard .r2 .pairMain .pairRow .perc {
  font-size: 13px;
  color: #bebebe;
}
.copyTradePage .statsRow {
  padding: 15px;
  /* border: 1px solid #E6E6E6; */
  background: var(--themeBg);
  border-radius: 11px;
  margin: 20px 0;
  color: #fff;
}
.copyTradePage .statsRow .r1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copyTradePage .statsRow .r1 .head {
  font-size: 18px;
  font-weight: 500;
}
.copyTradePage .statsRow .r1 .themeBtn {
  font-size: 12px;
}
.copyTradePage .statsRow .statusRow {
  margin: 5px 0 16px 0;
}
.copyTradePage .statsRow .statusRow .badge {
  /* background: #ECF7FF; */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  font-size: 9px;
  padding: 6px 10px;
  max-width: 90px;
  width: 100%;
  color: #fff;
  margin-right: 8px;
}
.copyTradePage .statsRow .assetRow {
  display: flex;
  align-items: center;
}
.copyTradePage .statsRow .assetRow .fugure {
  text-align: center;
  width: 100%;
  padding: 16px;
  border-right: 1px solid #e6e6e6;
}
.copyTradePage .statsRow .assetRow .fugure .val {
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
}
.copyTradePage .statsRow .assetRow .fugure .title {
  color: #bebebe;
  font-size: 12px;
}
.copyTradePage .dataRow .col1 {
  /* background: #ECF7FF; */
  background: var(--themeBg);
  border-radius: 11px;
  padding: 15px;
  height: 100%;
  color: #fff;
}
.copyTradePage .dataRow .col1 .r1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.copyTradePage .dataRow .col1 .r1 .title {
  font-size: 14px;
  font-weight: 500;
}
.copyTradePage .dataRow .col1 .r1 select {
  min-width: 55px;
  /* background: #fff; */
  background: rgba(247, 248, 252, 0.1);
  border: none;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 11px;
  color: #bebebe;
}
.tradeDetailPage .dataRow .col1 .dataMain:last-child {
  margin-bottom: 0;
}
.tradeDetailPage .dataRow .col1 .dataMain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tradeDetailPage .dataRow .col1 .dataMain .dCol:last-child {
  margin-right: 0;
}
.tradeDetailPage .dataRow .col1 .dataMain .dCol {
  margin-right: 10px;
  min-width: 90px;
}
.tradeDetailPage .dataRow .col1 .dataMain .dCol .perc {
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
}
.tradeDetailPage .dataRow .col1 .dataMain .dCol .source {
  font-size: 10px;
  /* color: #969696; */
  color: var(--themeGreen);
}
.copyTradePage .dataRow .col1 .pairMain .pairBtn {
  /* background: white; */
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  font-size: 10px;
  color: #bebebe;
  padding: 6px;
  margin: 5px;
}
.followTraderPage .statsRow {
  /* background: var(--themeBg); */
  color: #fff;
  padding: 15px;
  /* border: 1px solid #E6E6E6; */
  /* border-radius: 11px; */
  margin: 20px 0;
  display: flex;
}
.followTraderPage .dataRow .col1 .balanceBtn .innerBtn {
  height: 28px;
}
.followTraderPage .dataRow .col1 .balanceBtn {
  font-size: 10px;
  font-family: "Space Grotesk";
}
.followTraderPage .profileCard {
  /* max-width: 650px; */
}
.followTraderPage .profileCard .row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #3c3c3c;
}
.followTraderPage .profileCard .row1 .name {
  color: #fff;
  font-family: tt-firs-bold;
  text-transform: capitalize;
  font-size: 20px;
}
.followTraderPage .profileCard .row1 .followers {
  color: #bebebe;
  font-family: Space Grotesk;
  font-size: 14px;
}
.followTraderPage .dataRow .col1 {
  position: relative;
  /* border: 1px solid #E6E6E6; */
  border-radius: 11px;
  /* padding: 20px; */
  height: 100%;
  /* background: radial-gradient(75.67% 42.09% at 50.01% 58.05%, #4A2E9F 0%, #03003F 91.68%); */
  color: #fff;
}
.followTraderPage .dataRow .col1 .content {
  padding: 15px 20px;
}
.followTraderPage .dataRow .col1 .r1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid #3c3c3c;
}
.followTraderPage .dataRow .col1 .r1 .title {
  font-size: 18px;
  color: #fff;
  font-family: jetbrain-Bold;
}
.followTraderPage .dataRow .col1 .r1 select {
  min-width: 55px;
  /* background: #fff; */
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 11px;
  color: #bebebe;
  border-radius: 7px;
  border: 1px solid #3c3c3c;
  background: #111525;
}
.followTraderPage .dataRow .col1 .css-b62m3t-container .css-1nmdiq5-menu {
  background-color: #152e4c;
  z-index: 3;
}
.followTraderPage .dataRow .col1 .css-b62m3t-container > div:last-child:focus {
  outline: none !important;
}
.followTraderPage .dataRow .col1 .css-b62m3t-container > div:last-child {
  /* background-color: #152e4c; */
  background-color: #111525 !important;
  border-radius: 25px !important;
  color: #bebebe;
  border: none;
  outline: none !important;
}
.followTraderPage .dataRow .col1 .css-a0izu-control {
  background-color: var(--themeBg);
}
.followTraderPage .dataRow .col1 .css-t3ipsp-control {
  background-color: var(--themeBg);
}
.followTraderPage .dataRow .col1 .css-1u9des2-indicatorSeparator {
  background-color: transparent;
}
.followTraderPage .dataRow .col1 .css-13cymwt-control {
  background-color: transparent;
  border-color: transparent;
}
.followTraderPage .statsRow .assetRow {
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.followTraderPage .statsRow .assetRow .fugure:last-child {
  border: none;
}
.followTraderPage .statsRow .assetRow .fugure {
  text-align: center;
  width: 100%;
  padding: 16px 10px;
  /* border-right: 1px solid #E6E6E6; */
  border-right: 1px solid rgba(230, 230, 230, 0.2);
}
.followTraderPage .statsRow .assetRow .fugure .val {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  font-family: Space Grotesk, serif;
}
.followTraderPage .statsRow .assetRow .fugure .title {
  /* color: #969696; */
  color: var(--themeGreen);
  font-size: 12px;
  margin-bottom: 0;
}
.followTraderPage .statsRow .pMain {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  margin-right: 16px;
}
.followTraderPage .statsRow .pMain .imgDiv {
  width: 90px;
  height: 90px;
  /* border-radius: 50%; */
  overflow: hidden;
  margin-right: 12px;
}
.followTraderPage .statsRow .pMain .imgDiv img {
  width: 100%;
  height: auto;
}
.followTraderPage .statsRow .pMain .name {
  font-size: 18px;
  font-weight: bold;
}
.followTraderPage .statsRow .pMain .followers {
  font-size: 10px;
  color: #969696;
}
.followTraderPage .balanceBtn .innerBtn {
  height: 28px;
}
.followTraderPage .balanceBtn {
  width: max-content;
  font-size: 12px;
}
.followTraderPage {
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 15px;
  color: #fff;
}
.followTraderPage .header .balanceBtn img {
  width: 15px;
}
.followTraderPage .header .balanceBtn .innerBtn {
  height: auto;
}
.followTraderPage .header .balanceBtn {
  max-width: 130px;
  font-size: 12px;
  cursor: pointer;
}
.followTraderPage .header {
  font-family: jetbrain-Bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: var(--themeGreen);
}
.followBtn {
  background: var(--themeGreen);
  border: none;
  padding: 8px 25px;
  color: white;
  font-weight: 500;
  border-radius: 7px;
}
.followTraderPage .dataRow .col1 .pairMain {
  padding: 15px 20px;
}
.followTraderPage .dataRow .col1 .pairMain .pairBtn {
  /* background: #F7F8FC; */
  text-align: center;
  font-size: 12px;
  /* color: #707070; */
  color: #fff;
  padding: 6px;

  border-radius: 7px;
  border: 1px solid #3c3c3c;
  background: #111525;
  max-width: 112px;
  text-align: center;
  margin: 5px auto;
}
.followTraderPage .themeOutlineBtn {
  max-width: 84px;
  width: 100%;
  text-align: center;
}
.followTraderPage .riskRow {
  margin-top: 20px;
  margin-bottom: 20px;
}
.followTraderPage .riskRow .modeCard {
  position: relative;
  /* border: 1px solid #E6E6E6; */
  /* background: radial-gradient(75.67% 42.09% at 50.01% 58.05%, #4A2E9F 0%, #03003F 91.68%); */
  color: #fff;
  border-radius: 11px;
  /* padding: 20px 25px; */
  height: 100%;
  /* margin: 20px 0; */
}
.followTraderPage .riskRow .btnRightCut {
  height: 28px;
  font-size: 12px;
  max-width: 110px;
}
.followTraderPage .riskRow .r1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #3c3c3c;
  position: relative;
}
.followTraderPage .riskRow .inputMai {
  position: relative;
}
.followTraderPage .riskRow .inputMai .currency {
  position: absolute;
  right: 20px;
  color: #bebebe;
  top: 32px;
}
.followTraderPage .riskRow .r1 .title {
  font-weight: 500;
  color: #fff;
  font-family: tt-firs-bold;
  text-transform: uppercase;
  font-size: 20px;
}
.followTraderPage .riskRow .content {
  padding: 15px 20px;
}
.followTraderPage .riskRow .selectMode .balanceBtn {
  background: var(--themeGreen);
  max-width: 130px;
}
.followTraderPage .riskRow .selectMode .balanceBtn .innerBtn {
  font-size: 12px;
  height: 28px;
  color: var(--themeGreen);
}
.followTraderPage .riskRow .selectMode {
  display: flex;
  width: max-content;
  padding: 7px 10px;
  border-radius: 25px;
  background: #111525;
}
.followTraderPage .riskRow .selectMode .mode.active {
  color: #111525;
  background: var(--themeGreen);
}
.followTraderPage .riskRow .selectMode .mode {
  /* background: #F7F8FC; */
  background: var(--themeBg);
  text-align: center;
  font-size: 12px;
  /* color: #BEBEBE; */
  color: #fff;
  padding: 6px;
  /* margin-right: 10px; */
  /* margin-bottom: 10px; */
  border-radius: 25px;
  min-width: 100px;
  font-weight: 500;
}
.followTraderPage .selectM {
  padding: 2px;
  border-radius: 25px;
  border: 1px solid #3c3c3c;
  background: #111525;
}
.followTraderPage .selectM .borderImg img {
  width: 100%;
  height: 100%;
}
.followTraderPage .selectM .borderImg {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.followTraderPage .modeCard input::placeholder {
  color: #bebebe;
}
.followTraderPage .modeCard input {
  border: none;
  /* background: #F7F8FC; */
  color: #bebebe;
  font-size: 14px;
  /* margin: 12px 0; */
  border-radius: 25px;
  border: 1px solid #3c3c3c;
  background: #111525;
  padding: 12px 25px;
  height: 43px;
}
.followTraderPage .modeCard .tagline {
  font-size: 12px;
  color: #bebebe;
  font-family: "Space Grotesk";
}
.followTraderPage .modeCard .desc {
  /* color: #969696; */
  color: var(--themeGreen);
  font-size: 16px;
}
.followTraderPage .modeCard .themeBtn {
  /* font-size: 11px; */
}
.riskMMain label {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0;
}
.riskMMain .riskRow {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.riskMMain .riskRow .range {
  padding-right: 20px;
  flex: 1 0 0;
}
.riskMMain .riskRow .valM {
  padding: 6px 13px;
  background: rgba(247, 248, 252, 0.15);
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  max-width: 130px;
  width: 100%;
  align-items: center;
}
.riskMMain .css-1gv0vcd-MuiSlider-track {
  border: 1px solid var(--themeGreen) !important;
  background-color: var(--themeGreen) !important;
}
.riskMMain .css-eg0mwd-MuiSlider-thumb {
  background-color: var(--themeGreen) !important;
}
.riskMMain .css-14pt78w-MuiSlider-rail {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.profileImgBorder {
  padding: 1px;
  background: var(--themeBlue);
  clip-path: polygon(50% 0, 100% 20%, 100% 81%, 52% 100%, 0 80%, 0 20%);
}
.profileImgBorder .profileImgBorderInner {
  padding: 8px;
  background: #0e0023;
  clip-path: polygon(50% 0, 100% 20%, 100% 81%, 52% 100%, 0 80%, 0 20%);
}
.profileImgBorder .profileImgBorderInner .imgDiv {
  clip-path: polygon(50% 0, 100% 20%, 100% 81%, 52% 100%, 0 80%, 0 20%);
  /* padding: 8px; */
}

.tradeDetailPage .tDetailBanner {
  height: 178px;
  /* background: url(../../images/webp/profileBanner.webp) !important; */
  background: url(../../images/profileBanner.png) !important;
  background-position: center !important;
  background-size: cover;
  align-items: flex-end;
  position: relative;
  border-bottom: 20px;
  overflow: hidden;
  margin-bottom: 0;
  background-repeat: no-repeat;
}
.tradeDetailPage .statsRow .pMain {
  display: block;
  text-align: center;
  position: relative;
  top: -68px;
  /* max-width: 130px; */
  max-width: max-content;
  width: 100%;
  margin-left: 50px;
}
.tradeDetailPage .statsRow .pMain .imgDiv {
  margin: auto;
  width: 110px;
  height: 110px;
  border: 4px solid var(--themeGreen);
  background: white;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.profileBannerMain {
  /* padding: 1px; */
  /* background: var(--themeBlue); */
  /* clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%); */
}
.profileBannerMain .profileBorder {
  padding: 8px;
  background: #0e0023;
  clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
}
.profileBannerMain .profileBorder .profileBInner {
  padding: 1px;
  background: var(--Gradient);
  clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
}

/* .tradeDetailPage .profileBannerMain .profileBorder img {
    width: 100%;
    height: 100%;
} */
.tradeDetailPage .row2 {
  /* padding: 8px 15px;
    background: radial-gradient(54.83% 63.2% at 59.75% 54.65%, #45329C 0%, #090040 92%); */
}
.tradeDetailPage .statsRow .pMain .name {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 7px);
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.2;
  color: var(--themeGreen);
  font-family: tt-firs-bold;
  font-size: 20px;
  text-transform: capitalize;
}

.tradeDetailPage .table th > div {
  padding: 10px;
}
.tradeDetailPage .table td > div {
  padding: 10px 0;
  /* background: #F7F8FC; */
  /* background: var(--themeBg1); */
}
.tradeDetailPage .table th:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.tradeDetailPage .table th:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.tradeDetailPage .table tr td:first-child > div {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.tradeDetailPage .table tr td:last-child > div {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.tradeDetailPage .table .innerDiv .volume,
.tradeDetailPage .table tr td {
  font-size: 12px;
  color: white;
}
.tradeDetailPage .table .innerDiv {
  padding: 10px;
  min-height: 44px;
  /* border-right: 1px solid #e1e6f5; */
  border-right: 1px solid var(--themeBg1);
}
.tradeDetailPage .table th {
  /* background: #F7F8FC; */
  background: var(--themeBg);
}
.tradeDetailPage .table th > div {
  font-family: tt-firs-bold, serif;
  font-weight: 500;
  font-size: 16px;
  /* color: #707070; */
  color: var(--themeGreen);
  text-transform: uppercase;
  text-align: center;
}
.tradeDetailPage .table th > div span {
  width: max-content;
  margin: auto;
  display: inline-block;
}
.tradeDetailPage .table th,
.tradeDetailPage .table td {
  border: none;
  text-align: center;
  padding: 8px 0;
}
.tradeDetailPage .table th,
.tradeDetailPage .exIco {
  /* max-width: 59px; */
  margin: auto;
}
@media (max-width: 768px) {
  .followTraderPage .statsRow {
    flex-direction: column;
  }
  .followTraderPage .statsRow .assetRow .fugure .val {
    font-size: 19px;
  }
  .followTraderPage .statsRow .assetRow .fugure .title {
    font-size: 10px;
  }
  .copyTradePage .statsRow .assetRow .fugure .val {
    font-size: 19px;
  }
  .copyTradePage .statsRow .assetRow .fugure .title {
    font-size: 10px;
  }
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-btn {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 992px) {
  .followTraderPage .statsRow .assetRow {
    flex-wrap: wrap;
  }
  .followTraderPage .statsRow {
    padding-left: 0 !important;
    padding-right: 0;
  }
  .followTraderPage .statsRow .assetRow .fugure {
    width: 50%;
  }
  .followTraderPage .riskRow .selectMode {
    flex-wrap: wrap;
  }
  .followTraderPage .riskRow .selectMode .mode {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .followTraderPage .profileCard {
    margin-bottom: 20px;
  }
  .followTraderPage .riskRow .r1 {
    border-top: none;
  }
}
@media screen and (max-width: 576px) {
  .followTraderPage .dataRow .col1 .r1 .title {
    font-size: 18px;
  }
  .followTraderPage .modeCard .themeBtn {
    width: max-content;
  }
  .followTraderPage {
    padding: 0;
  }
  .copyTradePage {
    padding: 0;
  }
  .copyTradePage .followProfileCard .r1 {
    flex-wrap: wrap;
  }
}
.card {
  /* background: transparent; */
  background: var(--themeBg);
}
 .lineChartPieCMain {
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid #3c3c3c;
}
.lineChartPieCMain .price {
  display: flex;
  align-items: center;
}
.lineChartPieCMain .price h3 {
  color: #fff;
  font-family: titillium-web;
  font-size: 26px;
  margin: 0;
  margin-right: 10px;
}
 .lineChartPieCMain .price span {
  color: var(--themeGreen);
  font-size: 16px;
}
 .lineChartPieCMain .selectChartBtn .btnRightCut .ico {
  height: 8px;
  position: relative;
  width: auto;
  margin-right: 5px;
}
 .lineChartPieCMain .selectChartBtn .btnRightCut span {
  color: var(--themeGreen);
}
 .lineChartPieCMain .selectChartBtn .btnRightCut {
  padding: 6px 10px;
  font-size: 9px;
  height: auto;
  /* max-width: 100px;
    width: 100%; */
  flex-shrink: 0;
  width: 100%;
  max-width: 95px;
}
 .lineChartPieCMain .selectChartBtn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
