.referalLandingPage .sec1 h1 {
    font-size: 50px;
}
.referalLandingPage .sec1 .content {
    max-width: 100%;
}
.referalLandingPage .sec1 .desc span {
    color: #67E1AE;
}
.referalLandingPage .howItSec {
    background-color: var(--themeGreen);
    padding: 30px 0;
    text-align: center;
    color: #0F1016;
    margin-bottom: 50px;
}
.referalLandingPage .howItSec h2 {
    font-size: 45px;
    font-family: blomberg, serif;
}
.referalLandingPage .howItSec .desc {
    font-size: 18px;
}
.referalLandingPage .beakdownSec {
    padding: 50px 0;
}
.referalLandingPage .beakdownSec .homeHeading {
    color: #fff;
    text-align: center;
}
.referalLandingPage .beakdownSec .tagline {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
}
.referalLandingPage .beakdownSec .table-responsive {
    background-color: rgba(255, 255,255, 0.08);
    border: 1px solid rgba(255, 255,255, 0.2);
    border-radius: 12px;
}
.referalLandingPage .beakdownSec table th {
    background-color: rgba(255, 255,255, 0.2);
    color: var(--themeGreen);
    font-size: 26px;
    padding: 10px 25px;
    border-top: none;
    border-right: 1px solid rgba(255, 255,255, 0.2);
}
.referalLandingPage .beakdownSec table td {
    color: #fff;
    font-size: 26px;
    padding: 10px 25px;
    border-color: rgba(255, 255,255, 0.2);
    background-color: transparent;
}
.referalLandingPage .beakdownSec table tr td:first-child {
    border-right: 1px solid rgba(255, 255,255, 0.2);
}
.referalLandingPage .beakdownSec .total {
    background-color: rgba(255, 255,255, 0.08);
    border: 1px solid rgba(255, 255,255, 0.2);
    font-size: 22px;
    max-width: 600px;
    padding: 15px;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    margin-top: 20px;
}
.referalLandingPage .claimSec {
    padding: 50px 0;
}
.referalLandingPage .claimSec .tagline {
    color: #FFF;
    font-family: 'Space Grotesk';
    font-size: 20px;
    margin: auto;
    margin-bottom: 40px;
}
.referalLandingPage .claimSec .cCard {
    border-radius: 15px;
    border: 1px solid #3C3C3C;
    background: linear-gradient(174deg, #201F21 -1.2%, rgba(32, 31, 33, 0.00) 380.04%);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
    padding: 25px 55px;
    font-size: 20px;
}
.referalLandingPage .claimSec .cCard p span {
    color: var(--themeGreen);
    font-weight: 600;
    margin-right: 5px;
    font-size: 22px;
}
.referalLandingPage .startEarnSec {
    margin: 60px 0;
    padding: 30px 40px;
    border: 1px solid #3C3C3C;
    background: linear-gradient(174deg, #201F21 -1.2%, rgba(32, 31, 33, 0.00) 380.04%);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}
.referalLandingPage .startEarnSec p {
    text-align: center;
    max-width: 1080px;
    margin: auto;
    font-size: 17px;
}
.referalLandingPage .startEarnSec p span {
    color: var(--themeGreen);
}
@media screen and (max-width:576px) {
    .referalLandingPage .sec1 h1 {
        font-size: 37px;
    }
    .referalLandingPage .howItSec .desc{
        font-size: 16px;
    }
    .referalLandingPage .beakdownSec table th {
        font-size: 16px;
        padding: 10px 15px;
    }
    .referalLandingPage .beakdownSec table td {
        font-size: 16px;
        padding: 10px 15px;
    }
    .referalLandingPage .beakdownSec .total {
        font-size: 18px;
    }
    .referalLandingPage .claimSec .cCard {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
    }
    .referalLandingPage .claimSec .cCard p span {
        font-size: 18px;
    }
    .referalLandingPage .startEarnSec {
        padding-left: 20px;
        padding-right: 20px;
    }
}