.settingsPage .settingCardMain {
  height: 100%;
}
.settingsPage h6 {
  font-size: 18px;
  /* font-weight: bold; */
  margin-bottom: 0px;
  /* margin-top: 15px; */
}
.settingsPage .settingCardMain .settingCard .header {
  color: #fff;
  align-items: center;
  padding-bottom: 15px;
}
.settingsPage .settingCardMain .settingCard {
  padding: 15px;
  background: var(--cardBg);
  border-radius: 11px;
  height: 100%;
  color: #fff;
  position: relative;
  /* height: calc(100% - 35px) ; */
}
.settingsPage .settingCardMain .settingCard .cardIco {
  /* position: absolute;
    top: 15px;
    right: 15px; */
  height: 32px;
}
.settingsPage .key {
  font-size: 14px;
  font-weight: 800;
}
.settingsPage .val {
  color: #bebebe;
  font-size: 14px;
}
.settingsPage .key span {
  color: #bebebe;
}
.settingsPage input {
  border: none;
  padding: 10px 12px;
  /* background: #F7F8FC; */
  /* background: var(--themeBg1); */
  background: transparent;
  border: 1px solid #3c3c3c;
  color: #bebebe;
  font-size: 14px;
  /* margin: 12px 0; */
  width: 100%;
  border-radius: 25px;
}
.settingsPage .referalCode {
  display: flex;
  align-items: center;
}
.settingsPage .referalCode .themeBtn {
  padding: 10px 25px;
}
.settingsPage .row2 {
  padding: 15px;
  background: var(--cardBg);
  border-radius: 11px;
  height: 100%;
  color: #fff;
  position: relative;
}
.settingsPage .row2 .header h6 {
  font-size: 24px;
}
.settingsPage .row2 .header {
  color: #fff;
  padding-bottom: 15px;
}
.settingsPage .switchMain {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.settingsPage .switch {
  /* padding: 10px; */
}
.settingsPage .row2 .reset {
  max-width: 130px;
  width: 100%;
}
.ai-center {
  align-items: center !important;
}
.settingsPage .css-1yjjitx-MuiSwitch-track {
  background-color: rgba(255, 255, 255, 0.2);
}
.settingsPage
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: var(--themeGreen);
  opacity: 1;
}
.settingsPage .css-jsexje-MuiSwitch-thumb {
  background-color: #fff;
}
