@font-face {
    font-family: Poppins;
    src: url(../../assets/font/Poppins/Poppins-Regular.ttf);
}
@font-face {
    font-family: 'Space Grotesk';
    src: url(../../assets/font/Space-Grotes/SpaceGrotesk-Regular.ttf);
}

.landingPage {
    background: #0F1016;
    min-height: 100vh;
    color: #fff;
    overflow: hidden;
}
.landingPage .navbar-brand img {
    width: 118px;
}
.landingPage .navbar li a {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.landingPage .navbar li a:hover {
    color: var(--themeGreen);
}
 .themeBtnHome {
    border-radius: 25px;
    background: var(--themeGreen);
    padding: 10px 20px;
    color: #111;
    border: 2px solid var(--themeGreen);
    min-width: 145px;
    display: inline-block;
    /* font-family: 'Space Grotesk'; */
    font-family: titillium-web-bold;
    /* width: 100%; */
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}
 .themeBtnHome:hover {
    box-shadow: 0 0 10px 0 var(--themeGreen) inset, 0 0 20px 2px var(--themeGreen);
    border: 2px solid var(--themeGreen);
    text-decoration: none;
    color: var(--themeBg);
}
 .themeBtnHomeOutline {
    background: transparent;
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--themeGreen);
    border: 2px solid var(--themeGreen);
    min-width: 145px;
    display: inline-block;
    /* font-family: 'Space Grotesk'; */
    font-family: titillium-web-bold;
    /* width: 100%; */
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}
.themeBtnHomeOutline:hover {
     background: var(--themeGreen);
    box-shadow: 0 0 10px 0 var(--themeGreen) inset, 0 0 20px 2px var(--themeGreen);
    border: 2px solid var(--themeGreen);
    color: #111;
}
.landingPage .sec1 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 50px 0;
}
.landingPage .sec1 .content {
    max-width: 480px;
}
.landingPage .sec1 .content .Cursor {
    display: none;
}

.landingPage .sec1 h1 {
    color: var(--themeGreen);
    font-family: jetbrain-Bold;
    font-size: 70px;
    font-weight: 400;
}
.landingPage .sec1 .desc {
    font-size: 18px;
    font-family: 'Space Grotesk';
    /* font-weight: 500; */
    margin-bottom: 25px;
}
.landingPage .sec1 .imgDiv {
    position: relative;
}
.landingPage .sec1 .imgDiv .img-fluid {
    position: relative;
    top: 0;
}
.landingPage .sec1 .imgDiv .effect {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, #67E1AE 0%, rgba(15, 16, 22, 0.00) 100%);
    filter: blur(25px);
    width: 150%;
    height: 150%;
    opacity: 0.8;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    animation: moveLeftRight2 8s linear infinite ;
}
@keyframes moveLeftRight2 {
    0%,
    100% {
      top: 30%;
    }
  
    50% {
      top: 85%;
    }
}
@keyframes updown1 {
    0%,
    100% {
      top: -10px;
    }
  
    50% {
      top: 30px;
    }
}

.landingPage .sec1 .imgDiv .effect {
    position: absolute;
}


.landingPage .sec2 {
    padding: 25px 0;
    background: var(--themeGreen);
    margin-bottom: 50px;
}
.landingPage .sec2 .sec2Row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}
.landingPage .sec2 .sec2Row .item {
    color: #111;
    font-family: jetbrain-Bold;
    font-size: 48px;
    padding: 0 15px;
}
.landingPage .sec2 .sec2Row .item span {
    font-size: 32px;
}
.landingPage .sec3 {
    padding: 60px 0;
}
.landingPage .sec3 .homeHeading {
    margin-bottom: 40px;
    text-align: center;
}
.landingPage .sec3 .coinCard {
    border-radius: 7px;
    border: 1px solid #3C3C3C;
    background: #201F21;
    padding: 16px;
    text-align: center;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.landingPage .sec3 .coinCard .okx {
    height: 37px;
}
.landingPage .sec3 .coinCard img {
    height: 43px;
    transform: scale(0.9);
    animation: beat .7s infinite alternate;
	transform-origin: center;
}
.color-white {
    color: #fff;
}
@keyframes beat{
	to { transform: scale(1.1); }
}

.landingPage .homeHeading {
    color: var(--themeGreen);
    font-family: jetbrain-Bold;
    font-size: 45px;
    font-weight: 400;
}
.landingPage .sec4 {
    padding: 60px 0;
}
.landingPage .sec4 .content {
    max-width: 560px;
    margin-left: auto;
}
.landingPage .sec4 .desc {
    font-size: 17px;
    font-family: 'Space Grotesk';
    /* font-weight: 500; */
    margin-bottom: 25px;
}
.landingPage .sec4 .imgDiv {
    position: relative;
}
.landingPage .sec4 .imgDiv .img-fluid {
    position: relative;
}
.landingPage .sec4 .imgDiv .effect {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, #67E1AE 0%, rgba(15, 16, 22, 0.00) 100%);
    filter: blur(25px);
    width: 110%;
    height: 110%;
    opacity: 0.7;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    animation: moveLeftRight1 7s linear infinite ;
}
.landingPage .videoSec {
    max-width: 900px;
    margin: 50px auto;
    border-radius: 15px;
    border: 1px solid #3C3C3C;
    background: linear-gradient(174deg, #201F21 -1.2%, rgba(32, 31, 33, 0.00) 380.04%);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
    padding: 25px;
}
.landingPage .videoSec video {
    border-radius: 15px;
}
@keyframes moveLeftRight1 {
    0%,
    100% {
      left: 35%;
    }
  
    50% {
      left: 65%;
    }
}
.landingPage .sec4 .smHead {
    color: var(--themeGreen);
    font-family: 'Space Grotesk';
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}
.landingPage .sec6 {
    padding: 60px 0;
    text-align: center;
}
.landingPage .sec6 .desc {
    font-size: 20px;
    font-family: 'Space Grotesk';
    /* font-weight: 500; */
    margin-bottom: 25px;
}
.landingPage .sec5 {
    padding: 60px 0;
    text-align: center;
}
.landingPage .sec5 .secTitle {
    color: var(--themeGreen);
    font-family: 'Space Grotesk';
    font-size: 22px;
}
.landingPage .sec5 .homeHeading {
    color: #fff;
}
.landingPage .sec5 .secDesc {
    color: #FFF;
    font-family: 'Space Grotesk';
    font-size: 20px;
    margin: auto;
    margin-bottom: 40px;
    max-width: 825px;
}
.landingPage .sec5 .cCard {
    border-radius: 15px;
    border: 1px solid #3C3C3C;
    background: linear-gradient(174deg, #201F21 -1.2%, rgba(32, 31, 33, 0.00) 380.04%);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
    padding: 25px;
}
.landingPage .sec5 .cCard .imgDiv img {
    max-height: 100%;
}
.landingPage .sec5 .cCard .imgDiv {
    height: 118px;
    margin-bottom: 5px;
}
.landingPage .sec5 .cCard .title {
    color: var(--themeGreen);
    font-size: 35px;
    margin-bottom: 10px;
    font-family: jetbrain-Bold;
    line-height: 1.2;
}
.landingPage .sec5 .cCard .desc {
    color: #FFF;
    font-family: 'Space Grotesk';
    font-size: 17px;
    margin: 0;
}
.landingPage .sec7 {
    padding: 60px 0;
}
.landingPage .sec7 h1 {
    text-align: center;
    margin-bottom: 40px;
}
.landingPage .sec7 .tCard {
    border-radius: 7px;
    border: 1px solid #3C3C3C;
    background: #201F21;
    padding: 10px 20px;
    color: var(--themeGreen);
    font-family: jetbrain-Bold;
    font-size: 20px;
    display: flex;
    align-items: center;
    min-height: 78px;
    /* width: max-content; */
    max-width: 290px;
    margin: auto;
}
.landingPage .sec7 .tCard .imgDiv {
    max-width: 42px;
    margin-right: 12px;
}
.landingPage .trialSec {
    padding: 60px 0;
    text-align: center;
}
.landingPage .trialSec p {
    color:#FFF;
    font-family: 'Space Grotesk';
    font-size: 20px;
    margin-bottom: 30px;
}

.landingPage footer {
    margin-top: 50px;
    padding: 60px 0;
    border-top: 2px solid var(--themeGreen);
    font-family: Poppins;
    position: relative;
    overflow: hidden;
}
.landingPage footer .effect {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, #67E1AE 0%, rgba(15, 16, 22, 0.00) 100%);
    filter: blur(25px);
    width: 110%;
    height: 110%;
    opacity: 0.5;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    z-index: 0;
    animation: moveLeftRight 6s linear infinite ;
}
@keyframes moveLeftRight {
    0%,
    100% {
      left: 030%;
    }
  
    50% {
      left: 75%;
    }
}
.landingPage footer .logo {
    width: 118px;
    margin-bottom: 20px;
}
.landingPage footer .copyWrite {
    color: #F5F7FA;
    font-family: Poppins;
    font-size: 16px;
    margin-bottom: 0;
}
.landingPage footer .socialMain {
    display: flex;
    align-items: center;
    margin-top: 40px;
}
.landingPage footer .socialMain .item:hover {
    color: var(--themeGreen);
    text-decoration: none;
}
.landingPage footer .socialMain .item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: flex;align-items: center;
    justify-content: center;
    color: #fff;
    margin-right: 12px;
    cursor: pointer;
}

.landingPage footer .head {
    color: var(--themeGreen);
    font-family: jetbrain-Bold;
    font-size: 20px;
    margin-bottom: 25px;
}
.landingPage footer ul {
    list-style: none;
    padding: 0;
}
.landingPage footer ul li {
    margin-bottom: 10px;
}
.landingPage footer ul li a {
    color: #F5F7FA;
    font-size: 16px;
}
.landingPage footer ul li a:hover {
    color: var(--themeGreen);
    text-decoration: none;
}
.landingPage footer .mail {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    padding: 7px;
    display: flex;
    align-items: center;
}
.landingPage footer .mail img {
    cursor: pointer;
}
.landingPage footer .mail {
    flex-shrink: 0;
}
.landingPage footer .mail input {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background: transparent;
    border: none;
    color: #D9DBE1;
}
.landingPage footer .mail input::placeholder {
    color: #D9DBE1;
}
.landingPage .priceMarq {
    background: #171924;
    position: fixed;
    z-index: 33;
    bottom: 0;
}


@media (min-width: 1400px){
    .landingPage .container {
        max-width: 1320px;
    }
}
@media screen and (min-width:992px) {
    .landingPage .navbar-nav .nav-link {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width:992px) {
    .landingPage .sec4 .imgDiv {
        margin-bottom: 20px;
    }
    .landingPage .sec4 .content {
        margin-bottom: 20px;
    }
}
@media screen and (max-width:768px) {
    .landingPage footer .head {
        margin-top: 20px;
    }
}
@media screen and (max-width:576px) {
    .landingPage .sec1 h1 {
        font-size: 52px;
    }
    .landingPage .sec1 .desc {
        font-size: 16px;
    }
    .landingPage .sec2 .sec2Row .item {
        font-size: 42px;
    }
    .landingPage .sec2 .sec2Row .item span {
        font-size: 26px;
    }
    .landingPage .homeHeading {
        font-size: 37px;
    }
    .landingPage .sec5 .secDesc {
        font-size: 16px;
    }
    .landingPage .sec5 .cCard .title {
        font-size: 32px;
    }
}
.master-stats{
    font-size: 40px;
    color: var(--themeGreen);
    font-weight: 800;
}
.how-to-subs-step-num{
    font-size: 70px;
    color: var(--themeGreen);
    font-weight: 800;
    margin-right: 10px;
}
.how-to-subs-text-div{
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
}
.how-to-subs-step{
    display: flex;
    align-items: center;
}
.how-to-subs-sub-text{
    font-size: 16px;
    font-weight: 400;
}
.how-to-subs-text{
    font-size: 24px;
    font-weight: 800;
}
.tp-widget-wrapper{
    text-align: left;
    margin-top: 18px;
}