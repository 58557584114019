.profileBanner {
  height: 178px;
  background: url(../../images/profileBanner.png) !important;
  background-position: center !important;
  background-size: cover !important;
  align-items: flex-end;
  position: relative;
  border-bottom: 20px;
  overflow: hidden;
  background-repeat: no-repeat;
}
.profileBanner:hover .editPrMain {
  display: flex;
}
.profileBanner .editPrMain .icon {
  cursor: pointer;
}
.profileBanner .editPrMain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  padding: 20px;
  display: none;
  align-items: flex-end;
  justify-content: flex-end;
}
.tradeDetailPage .statsRow .pMain .imgDiv:hover .editPrMain {
  display: flex;
}
.tradeDetailPage .statsRow .pMain .imgDiv .editPrMain .icon {
  cursor: pointer;
}
.tradeDetailPage .statsRow .pMain .imgDiv .editPrMain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  padding: 20px;
  display: none;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 50%;
}
.followTraderPage .profileBanner .pMain .imgDiv {
  width: 60px;
  height: 60px;
}
.followTraderPage .profileBanner .pMain .name {
  font-weight: 500;
}
.profilePage .overRow {
  /* border-radius: 11px; */
  padding: 15px;
  height: 100%;
  /* background: var(--themeBg); */
  /* background: radial-gradient(54.83% 63.2% at 59.75% 54.65%, #45329C 0%, #090040 92%);    color: #fff; */
  display: flex;
  align-items: center;
  clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
}
.profilePage .overRowBorderM {
  padding: 1px;
  background: var(--themeBlue);
  clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
}
.profilePage .overRowBorderM .overRowBorder {
  padding: 8px;
  background: #0e0023;
  clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
}
.profilePage .overRowBorderM .overRowBorder .overRowBInner {
  padding: 1px;
  background: var(--Gradient);
  clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
}
.profilePage .overRow .overStats {
  text-align: center;
  width: 100%;
  padding: 12px;
  border-right: 1px solid rgba(230, 230, 230, 0.1);
}
.profilePage .overRow .overStats h5 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 22px;
}
.profilePage .overRow .overStats p {
  font-size: 11px;
  color: var(--Gradient);
  margin-bottom: 0;
}
.profilePage .chartOption {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  border-radius: 8px;
  /* background: var(--cardBg); */
  padding: 8px;
  margin: 16px 0;
  max-width: fit-content;
}
.profilePage .chartOption .cBtn.active {
  /* color: #707070; */
  background: var(--themeGreen);
  color: var(--themeBg);
}
.profilePage .chartOption .cBtn:last-child {
  margin: 0;
}
.profilePage .chartOption .cBtn {
  font-family: titillium-web-semibold, serif;
  padding: 4px 16px;
  /* background: #F7F8FC; */
  background: transparent;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
  color: #fff;
  min-width: 90px;
  text-align: center;
}

.profilePage table tr th:first-child {
  border-top-left-radius: 8px;
}
.profilePage table tr th:last-child {
  border-top-right-radius: 8px;
}
.profilePage table tr th {
  font-size: 10px;
  font-weight: 400;
  /* color: #84818A; */
  color: #ffffff;
  /* background: #05b4ef; */
}
.profilePage table tbody tr:nth-of-type(even) td {
  background: var(--themeBg);
}
.profilePage table tr td {
  font-size: 10px;
  font-weight: 400;
  /* color: #202020; */
  color: #fff;
}
.profilePage .nameMain {
  display: flex;
  align-items: center;
}
.profilePage .nameMain .imgDiv {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
}
.profilePage .nameMain .imgDiv img {
  width: 100%;
}
.profilePage .nameMain .currency {
  /* color: #202020; */
  color: #fff;
  font-weight: 600;
  margin-bottom: 2px;
}
.profilePage .nameMain .sf {
  color: #fff;
  /* color: #47464A; */
}
.profilePage .percMain {
  color: #47464a;
}

.profilePage .balanceMain {
  text-align: right;
  width: max-content;
}
.profilePage .balanceMain .balance {
  font-weight: 600;
}
.profilePage .balanceMain .usd {
  /* color: #84818A; */
  color: #bebebe;
}
.profilePage .priceMain {
  text-align: right;
  width: max-content;
}
