input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#webpack-dev-server-client-overlay{
  display: none;
}
.PhoneInputInternationalIconGlobe{
  display: none;
}