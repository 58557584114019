.portfolioPage {
  padding-left: 30px;
  padding-right: 15px;
  color: #fff;
}
.portfolioPage .profileBInnerM {
  padding: 20px;
  position: relative;
  /* background: radial-gradient(59.4% 49.45% at 63.52% 54.79%, #45329C 0%, #090040 92%); */
}
.portfolioPage
  .profileBannerMain
  .profileBorder
  .profileBInner
  .profileBInnerM {
  padding: 25px 35px;
}

.portfolioPage
  .balanceMain
  .profileBannerMain
  .profileBorder
  .profileBInner
  .profileBInnerM {
  padding: 20px;
}
.portfolioPage .balanceMain .balanceCard.loss {
  /* background: rgba(230,73,106,0.15); */
}
.portfolioPage .balanceMain .yourBalance {
  display: flex;
  align-items: center;
}
.portfolioPage .balanceMain .yourBalance .col1 {
  width: 173px;
  height: 173px;
  border-radius: 50%;
  border: 6px solid var(--themeGreen);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background:  #201F21; */
  background: var(--themeBg);
  /* background:  radial-gradient(72.04% 40.67% at 50.51% 49.88%, #45329C 0%, #090040 92%); */
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}
.portfolioPage .balanceMain .yourBalance .col1 .title {
  font-size: 10px;
}
.portfolioPage .balanceMain .yourBalance .col1 .balance {
  color: var(--themeGreen);
  font-family: jetbrain-Bold;
  font-size: 32px;
}
.portfolioPage .balanceMain .yourBalance .col2 {
  width: 100%;
  max-width: 210px;
  position: relative;
  left: -27px;
  z-index: 0;
}
.portfolioPage .balanceMain .yourBalance .col2 .profit1 {
  padding: 1px;
  background: var(--themeGreen);
  clip-path: polygon(87% 0, 100% 50%, 87% 100%, 0 100%, 0 0);
  margin: 8px 0;
}
.portfolioPage .balanceMain .yourBalance .col2 .profit1 .innerBorder {
  /* background: radial-gradient(72.04% 40.67% at 50.51% 49.88%, #45329C 0%, #090040 92%); */
  /* background: #201F21; */
  background: var(--themeBg);
  padding: 7px 35px;
  clip-path: polygon(87% 0, 100% 50%, 87% 100%, 0 100%, 0 0);
}
.portfolioPage .balanceMain .yourBalance .col2 .profit1 .title {
  font-size: 12px;
}
.portfolioPage .balanceMain .yourBalance .col2 .profit1 .balance {
  display: flex;
  align-items: center;
}
.portfolioPage .balanceMain .yourBalance .col2 .profit1 .balance img {
  width: 19px;
  margin-left: 15px;
  margin-right: 5px;
}
.portfolioPage .balanceMain .yourBalance .col2 .profit1 .balance .profit {
  color: #03c074;
  font-size: 13px;
}
.portfolioPage .balanceMain .yourBalance .col2 .profit1 .balance .loss {
  color: #e6496a;
  font-size: 13px;
}

.portfolioPage .balanceMain .balanceCard.profit {
  /* background: rgba(26,188,133,0.15); */
}
.portfolioPage .balanceMain .balanceCard {
  /* background: #FFF6E9; */
  background: var(--themeBg);
  border-radius: 8px;
  /* color: #000000; */
  color: #fff;
  padding: 25px 20px;
  height: 100%;
}
.portfolioPage .balanceMain .balanceCard .title {
  font-size: 11px;
  font-weight: bold;
}
.portfolioPage .balanceMain .balanceCard .balanceRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.portfolioPage .balanceMain .balanceCard .balanceRow .balance {
  font-size: 26px;
  font-weight: bold;
}
.portfolioPage .balanceMain .balanceCard .balanceRow .perc {
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}
.portfolioPage .balanceMain .balanceCard.profit .balanceRow .perc {
  color: #1abc85;
}
.portfolioPage .balanceMain .balanceCard .balanceRow .percDiv {
  display: flex;
  align-items: center;
}
.portfolioPage .balanceMain .balanceCard.loss .balanceRow .perc {
  color: #e6496a;
}
/* .portfolioPage .chartMain .card-header:first-child{
    background: transparent;
} */
.chartMain > .chart-area > .recharts-wrapper > .recharts-surface {
  overflow: visible;
}
.portfolioPage .chartMain {
  border-radius: 12px;
  padding: 0;
  /* border: 1px solid #E6E6E6; */
  /* background: var(--themeBg); */
}
.portfolioPage .card {
  /* background: transparent; */
  background: var(--themeBg);
}
.portfolioPage .chartOption .balanceBtn.active .innerBtn {
  color: var(--themeBlue);
}
.portfolioPage .chartOption .balanceBtn .innerBtn {
  height: 36px;
  font-size: 14px;
}
.portfolioPage .chartOption .balanceBtn.active {
  max-width: 158px;
  background: var(--themeBlue);
  opacity: 1;
}
.portfolioPage .chartOption .balanceBtn {
  max-width: 158px;
  opacity: 0.5;
}
.portfolioPage .chartOption {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  border-radius: 8px;
  background: var(--themeBg);
  padding: 8px;
  /* max-width: 460px; */
}
.portfolioPage .chartOption .cBtn.active {
  /* color: #707070; */
  color: #fff;
  background: rgba(247, 248, 252, 0.2);
}
.portfolioPage .chartOption .cBtn {
  position: relative;
  padding: 4px 10px;
  /* background: #F2F2F2; */
  background: transparent;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  /* max-width: 110px; */
  /* width: 100%; */
  text-align: center;
}
.dib {
  display: inline-block;
}
.portfolioPage .chartOption .cBtn.active {
  background: var(--themeGreen);
  color: var(--themeBg);
}
.portfolioPage .header .btnRightCut {
  padding: 15px 23px;
  font-size: 15px;
  height: 49px;
}
.portfolioPage .assetTableM .table th {
  font-family: desirableSans, serif;
  font-weight: 400;
  color: var(--themeGreen);
  text-transform: uppercase;
  background: transparent;
  border-bottom: unset !important;
}

.portfolioPage .header {
  font-family: desirableSans, serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  /* margin-top: 15px; */
  color: var(--themeGreen);
  flex-wrap: wrap;
}
.portfolioPage .header h2 span {
  font-weight: normal;
  font-size: 24px;
}

.portfolioPage .assetTable {
  margin-top: 20px;
  border-radius: 12px;
  /* border: 1px solid #E6E6E6; */
  /* background: var(--themeBg); */
}

.portfolioPage .assetOption {
  display: flex;
  align-items: center;
}
.portfolioPage .assetOption .cBtn.active {
  color: #05b4ef;
}
.portfolioPage .assetOption .cBtn:last-child {
  margin-right: 0;
}
.portfolioPage .assetOption .cBtn {
  padding: 4px 16px;
  /* background: #F7F8FC; */
  background: rgba(247, 248, 252, 0.1);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 15px;
  /* color: #BEBEBE; */
  color: #56687f;
}
.portfolioPage .assetTable .row1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.portfolioPage .assetTable .row1 .head {
  font-size: 12px;
  font-weight: 700;
}
.portfolioPage .assetTable table tr th {
  font-size: 16px;
  /* color: #84818A; */
  color: var(--themeGreen);
  font-family: tt-firs-bold;
  text-transform: uppercase;
}
.portfolioPage .assetTable table tr td {
  font-size: 10px;
  font-weight: 400;
  /* color: #202020; */
  color: #fff;
}
.portfolioPage .assetTable .nameMain {
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolioPage .assetTable .nameMain .imgDiv {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
}
.portfolioPage .assetTable .nameMain .imgDiv img {
  width: 100%;
}
.portfolioPage .assetTable .nameMain .currency {
  /* color: #202020; */
  color: #fff;
  font-weight: 600;
  margin-bottom: 2px;
}
.portfolioPage .assetTable .nameMain .sf {
  color: #fff;
  /* color: #47464A; */
}
.portfolioPage .assetTable .percMain {
  color: #47464a;
}
.portfolioPage .assetTable .percMain .slide1 {
  height: 6px;
  background: #eceaee;
  border-radius: 4px;
  position: relative;
  margin-bottom: 5px;
}
.portfolioPage .assetTable .percMain .slide2 {
  position: absolute;
  height: 6px;
  border-radius: 4px;
  width: 30%;
  background: green;
}
.portfolioPage .assetTable .balanceMain {
  text-align: right;
  width: max-content;
  margin: auto;
}
.portfolioPage .assetTable .balanceMain .balance {
  font-weight: 600;
}
.portfolioPage .assetTable .balanceMain .usd {
  /* color: #84818A; */
  color: #bebebe;
}
.portfolioPage .assetTable .priceMain {
  text-align: right;
  width: max-content;
  margin: auto;
}
.portfolioPage .assetTable .priceMain .perc {
  font-weight: 500;
  color: #009512;
}
.portfolioPage .assetTable .priceMain .perc.profit {
  color: #009512;
}
.portfolioPage .assetTable .priceMain .perc.loss {
  color: #f75151;
}

.portfolioPage .lineChartPieCMain {
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid #3c3c3c;
}
.portfolioPage .lineChartPieCMain .price {
  display: flex;
  align-items: center;
}
.portfolioPage .lineChartPieCMain .price h3 {
  color: #fff;
  font-family: titillium-web;
  font-size: 26px;
  margin: 0;
  margin-right: 10px;
}
.portfolioPage .lineChartPieCMain .price span {
  color: var(--themeGreen);
  font-size: 16px;
}
.portfolioPage .lineChartPieCMain .selectChartBtn .btnRightCut .ico {
  height: 8px;
  position: relative;
  width: auto;
  margin-right: 5px;
}
.portfolioPage .lineChartPieCMain .selectChartBtn .btnRightCut span {
  color: var(--themeGreen);
}
.portfolioPage .lineChartPieCMain .selectChartBtn .btnRightCut {
  padding: 6px 10px;
  font-size: 9px;
  height: auto;
  /* max-width: 100px;
    width: 100%; */
  flex-shrink: 0;
  width: 100%;
  max-width: 95px;
}
.portfolioPage .lineChartPieCMain .selectChartBtn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.portfolioPage .assetHeading {
  /* color: var(--themeGreen); */
  font-family: tt-firs-bold;
  font-size: 18px;
}
.portfolioPage .table th > div span {
  font-size: 16px;
}
.portfolioPage .table .innerDiv .profileDiv {
  display: flex;
  align-items: center;
  text-align: left;
}
.portfolioPage .table .innerDiv .profileDiv .imgDiv {
  width: 100%;
  height: 100%;
}
.portfolioPage .table .innerDiv .val {
  line-height: 1;
  color: #bebebe;
}
.portfolioPage .table .innerDiv .profileDiv .imgDiv {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.portfolioPage .table .innerDiv .percMain .slide {
  position: relative;
  height: 5px;
  width: 100%;
  background: #eceaee42;
  border-radius: 5px;
}
.portfolioPage .table .innerDiv .percMain .slide .slideInner.yellow {
  background: #f7931a;
}
.portfolioPage .table .innerDiv .percMain .slide .slideInner.green {
  background: #8ac072;
}
.portfolioPage .table .innerDiv .percMain .slide .slideInner.blue {
  background: #5169ca;
}
.portfolioPage .table .innerDiv .percMain .slide .slideInner {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}
.portfolioPage .table .innerDiv .profit {
  color: #009512;
  font-family: Space Grotesk;
  font-size: 10px;
}
.portfolioPage .table .innerDiv .loss {
  color: #f75151;
  font-family: Space Grotesk;
  font-size: 10px;
}
.portfolioPage .table th,
.portfolioPage .table td {
  text-align: center !important;
}
.portfolioPage .table th > div {
  text-align: left !important;
  /* color: white; */
}
.portfolioPage .table .innerDiv .volume,
.portfolioPage .table tr td {
  vertical-align: middle;
  color: white;
  font-size: 12px;
}
.portfolioPage .table td > div {
  background: transparent;
}
.portfolioPage .table td,
.portfolioPage .table th {
  border: none;
}
.portfolioPage .chart-heading {
  font-family: titillium-web;
  color: var(--themeGreen);
}
.assetTableM {
  padding: 30px;
}

@media screen and (min-width: 1200px) {
  /* .portfolioPage .balanceMain .profileBannerMain {
        clip-path: polygon(6% 0, 100% 0, 100% 87%, 94% 100%, 0 100%, 0 16%);
    } */
  .portfolioPage .balanceMain .profileBannerMain .profileBorder {
    clip-path: polygon(6% 0, 100% 0, 100% 87%, 94% 100%, 0 100%, 0 16%);
  }
  .portfolioPage .balanceMain .profileBannerMain .profileBorder .profileBInner {
    clip-path: polygon(6% 0, 100% 0, 100% 87%, 94% 100%, 0 100%, 0 16%);
  }
  .portfolioPage
    .balanceMain
    .profileBannerMain
    .profileBorder
    .profileBInner
    .profileBInnerM {
    clip-path: polygon(6% 0, 100% 0, 100% 87%, 94% 100%, 0 100%, 0 16%);
  }
}
@media screen and (max-width: 1200px) {
  .portfolioPage .balanceMain .profileBannerMain {
    clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
  }
  .portfolioPage .balanceMain .profileBannerMain .profileBorder {
    clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
  }
  .portfolioPage .balanceMain .profileBannerMain .profileBorder .profileBInner {
    clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
  }
  .portfolioPage
    .balanceMain
    .profileBannerMain
    .profileBorder
    .profileBInner
    .profileBInnerM {
    clip-path: polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%);
  }
  .portfolioPage .balanceMain .yourBalance {
    margin-bottom: 20px;
  }
  .portfolioPage .profileBannerMain {
    clip-path: polygon(6% 0, 100% 0, 100% 90%, 94% 100%, 0 100%, 0 10%);
  }
  .portfolioPage .profileBannerMain .profileBorder {
    clip-path: polygon(6% 0, 100% 0, 100% 90%, 94% 100%, 0 100%, 0 10%);
  }
  .portfolioPage .profileBannerMain .profileBorder .profileBInner {
    clip-path: polygon(6% 0, 100% 0, 100% 90%, 94% 100%, 0 100%, 0 10%);
  }

  .portfolioPage
    .profileBannerMain
    .profileBorder
    .profileBInner
    .profileBInnerM {
    clip-path: polygon(6% 0, 100% 0, 100% 90%, 94% 100%, 0 100%, 0 10%);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .portfolioPage .balanceMain .balanceCard .balanceRow .balance {
    font-size: 20px;
  }
  .portfolioPage .balanceMain .balanceCard .balanceRow .percDiv img {
    width: 30px;
  }
}
@media screen and (max-width: 576px) {
  .portfolioPage .balanceMain .yourBalance {
    flex-direction: column;
  }
  .portfolioPage .balanceMain .yourBalance .col2 {
    left: 0;
    margin-top: 10px;
  }
  .portfolioPage .header {
    justify-content: center;
  }
  .portfolioPage
    .profileBannerMain
    .profileBorder
    .profileBInner
    .profileBInnerM {
    padding: 20px;
  }
  .portfolioPage
    .balanceMain
    .profileBannerMain
    .profileBorder
    .profileBInner
    .profileBInnerM {
    padding: 10px;
  }
  .portfolioPage
    .balanceMain
    .profileBannerMain
    .profileBorder
    .card-header:first-child {
    flex-direction: column !important;
  }
  .portfolioPage .balanceMain .profileBannerMain .profileBorder .dropdown a {
    padding-right: 10px !important;
  }
  .portfolioPage .chartOption {
    justify-content: center;
  }
  .portfolioPage {
    padding: 0;
  }
}
.notApproveYet .col1 h1 {
  font-family: desirableSans, serif;
  /* font-weight: bold; */
  font-size: 24px;
  color: #fff;
  color: var(--themeGreen);
  /* text-shadow: 2px 2px 0px var(--Gradient); */
}
.notApproveYet .col1 p {
  font-size: 17px;
  margin: 25px 0;
  font-weight: lighter;
}
.notApproveYet .col2 .imgDiv {
  text-align: center;
}
